import React, { useState } from 'react'
import ButtonPlus from '../../Components/SelectValue/ButtonPlus'
import AddInvestment from '../../Components/Transaction/AddInvestment'
import Transactions from '../../Components/Transaction/Transactions'

function Investment() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <div>
    <AddInvestment
      open={open}
      handleClose={handleClose}
    />
      <ButtonPlus
        name='Add investment'
        onClick={handleOpen}
      />
      <Transactions/>
    </div>
  )
}

export default Investment