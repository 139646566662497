import React from 'react'
import Loginform from '../../Components/Login/Loginform'

function Login() {

  return (
    <div className='bg-signin-bg'>
    <div className='  grid w-10/12 md:w-8/12   mx-auto h-[100vh] place-items-center'>
   
      <Loginform/>
    </div>
    </div>
  )
}

export default Login