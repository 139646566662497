import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import Loading from '../SelectValue/Loading';
import Inputtt from '../SelectValue/Inputtt';
import TexttArea from '../SelectValue/TexttArea';
import postService from '../../Services/post-service';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function AddNotification({ open, handleClose}) {
    const [loading, setLoading] = useState(false)
    const [con, setCon] = useState('')
    const [header, setHeader] = useState('')
    const onSubmit = (e) =>{
            e.preventDefault()
           
                setLoading(true)
                postService.addNotification(header,con).then(
                    (response) => {
                      setLoading(false)
                    if(response.data.status === "2000"){
                      swal("Notification sent successfully!").then(
                        response =>{
                            window.location.reload()
                        }
                      )
                      console.log(response.data.data)
                    }else{
                        swal(response.data.description).then(
                            response =>{
                                window.location.reload()
                            }
                          )
                    }
                   
                    console.log (response.data.data)
                    
                  },
                  (error) => {
                      setLoading(false)
                      swal(error.message)
                  }
                  )
            
    }
   
  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
            open={loading}
          />
            <div className='  '>
                <div className=''>
                    <div className=' flex mb-6 justify-end'>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                    <h1 className=' font-bold text-2xl  mb-3'>Create Notification</h1>

                    <form onSubmit={onSubmit}>
                     <div>
                        <Inputtt
                            label='Title'
                            value={header}
                            onChange={setHeader}
                            type='text'
                        />
                        <div className=' mb-5'>
                        <TexttArea
                            label='Body'
                            value={con}
                            onChange={setCon}
                        />
                        </div>
                       <Button
                        name='Submit'
                       />
                     </div>
                    </form>
                    
                </div>
                
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}