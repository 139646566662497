import React from 'react'
import MainCon from '../../Components/Savings.js/MainCon'

function Savings() {
  return (
    <div>
      <MainCon/>
    </div>
  )
}

export default Savings