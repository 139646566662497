import React, {useEffect, useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import Loading from '../SelectValue/Loading'
import postService from '../../Services/post-service'
import swal from 'sweetalert';
import Logo from '../../Assets/images/Zlogo.png'
import cookie from 'react-cookies'
import { Link } from 'react-router-dom'
// import Constant from '../../Constant'
function ReseetPin() {
  const [loading, setLoading] = useState(false)
  const requestId = localStorage.getItem('reques')
    const initialValues = { 
        otp: '',

      }
      const validationSchema = Yup.object({
        otp: Yup.string()
        .matches(/^\d{6}$/, 'Otp must be a 6-digit number')
        .required('OTp is required'),   
            
      })

      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {otp} = values
        postService.reset2fa(otp,requestId).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
                swal('2fa reset succefully!').then(() =>{
                    return   window.location.href = '/'
                })

              // localStorage.setItem('token',response.data.data.token)
              
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  swal('Pin reset Failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
        // navigate('/dashboard')
      }

  return (
    <div className=' bg-white shadow-lg p-16 rounded-[16px]'>
    <Loading
      open={loading}
    />
        <div className=' '>
        <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
            <p className=' mt-6 text-[18px] text-back-color2 mb-5 md:text-[25px] font-[500]'>Zouse - Two Factor Authentication</p>
           
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            <div>
            
           
            </div>
               <FormikControl
                control='input'
                type='text'
                label='Enter Otp'
                maxLength="6"
                name='otp'
              />  
               {/* <FormikControl
                control='input'
                type='text'
                label='Enter pin'
                maxLength="4"
                name='pin'
              />   */}
             
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Submit'
              />
              </div>
              
              
        </Form>
        )}
            </Formik>
           
            </div>
        </div>
    </div>
  )
}

export default ReseetPin