import React from 'react'
import Button from '../SelectValue/Button'
import TransactionTable from './TransactionTable'

function Transactions() {
  return (
    <div>
         
            <TransactionTable/>
    </div>
  )
}

export default Transactions