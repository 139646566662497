import React, {useState, useEffect} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../SelectValue/Button';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import postService from '../../Services/post-service';
import swal from 'sweetalert';
import Loading from '../SelectValue/Loading';
import getServices from '../../Services/get-service';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function AddAdmin({open, handleClose}) {
  const [loading, setLoading] = useState(false)
  const [valueRole, setValueRole] = useState('')
  const details = localStorage.getItem('userdetails')
  const user =  JSON.parse(details)
  const [role, setRole] = useState([])
    const initialValues = { 
        fname: '',
        lname: '',
        email: '',
        phone: '',
      }

      useEffect(() =>{
        setLoading(true)
          getServices.getRole().then(
            (response) => {
              setLoading(false)
            if(response.data.status === "2000"){
              setRole(response.data.data)
              console.log(response.data.data)
            }else{
            //  swal(response.data.description)
            //  toast('Session Expired', {
            //   onClose: () => {
            //       window.location.href = '/'
            //   },
            //   autoClose: 5000
            // })
            }
           
            console.log(response.data.data)
            
          },
          (error) => {
              setLoading(false)
              // toast('Session Expired', {
              //     onClose: () => {
              //       window.location.href = '/'
              //     },
              //     autoClose: 5000
              //   })
          }
          )
      },[])
      const validationSchema = Yup.object().shape({
        fname: Yup.string().required('Required'),
        lname: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
        phone: Yup.string()
        .required('Phone number is required')
        .matches(/^\d{11}$/, 'Phone number must be exactly 11 digits'),
      })
      const onSubmit = values => {
        setLoading(true)
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
       const {fname, lname, email, phone} = values
        postService.userInvite(email,lname,fname, phone, valueRole).then(
          (response) => {
              console.log(response.data)
             setLoading(false)
             if(response.data.status === '2000'){
            
             
                  swal("Invite sent out there mail").then((result) => {
                    return   window.location.href = '/users'
                  })
              // localStorage.setItem('token',response.data.data.token)
             
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
             // setLoading(false)
                return  swal('Error')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
      
    
        // navigate('/verifyaccount')
      }
      // const dropdownOptions = [
      //   { key: 'Select an option', value: '' },
      //   { key: 'Admin', value: 'Admin' },
      //   { key: 'SuperAdmin', value: 'SuperAdmin' },
        
      // ]

      const filter =role.filter(item => item.inviteRole !== user.userRole);
  return (
    <div>
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'>
            <Loading
            open={loading}
          />
            <div className=' md:mt-8'>
                <div className=' flex justify-end my-5'>
                    <CancelRoundedIcon className=' cursor-pointer' onClick={handleClose} fontSize='large'/>
                </div>
                <div>
                    <p className=' my-5 text-center text-back-color2 font-[500] text-[24px]'>Add admin user </p>
                </div>
                <div>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            <div className=' grid mb-3 md:grid-cols-2 gap-5'>
            <FormikControl
                control='input'
                type='text'
                label='First name'
                name='fname'
              />  
               <FormikControl
                control='input'
                type='text'
                label='Last name'
                name='lname'
              />  
            </div>
            <div className=' grid  md:grid-cols-2 gap-5'>
            <FormikControl
                control='input'
                type='text'
                label='Phone number'
                name='phone'
              />  
            <div className=' grid mb-3 md:grid-cols-1 gap-5'>
            <FormikControl
                control='input'
                type='email'
                label='Email address'
                name='email'
              />  
            </div>
           
            </div>
              <select 
              value={valueRole} 
              onChange={(e) => setValueRole(e.target.value)} 
              required 
              className=' p-4 w-full outline-0 rounded-lg border bg-white '>
                <option>Select Role</option>
                {filter.map(option => {
                return (
                  <option key={option.inviteRole} value={option.inviteRole}>
                    {option.inviteRole}
                  </option>
                )
              })}
              </select>
               
           
            
              
              <div className=' grid grid-cols-1 mb-5 mt-10'>
              <Button
                name='Add'
              />
              </div>
           
              
        </Form>
        )}
            </Formik>
                </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}