import React, { useEffect, useState } from 'react'
import ProjectCom from '../Projects/ProjectCom'
import WithdrawalTable from './WithdrawalTable'
import getServices from '../../Services/get-service'

function Main() {
  const [pending, setPending] = useState('0')
  const [active, setActive] = useState('0')
  const [rej, setRejec] = useState('0')
  const [total, setTotal] = useState('0')
  useEffect(() =>{
    getServices.getAllCount().then(
        (response) => {
           
          if(response.data.status === "2000"){
            setActive(response.data.data.countSuccessWithdrawal)
            setPending(response.data.data.countPendingWithdrawal)
            setRejec(response.data.data.countRejectedWithdrawal)
            setTotal(response.data.data.totalwithdrawalAmount)
          }else{
          //  swal(response.data.description)
          //  toast('Session Expired', {
          //   onClose: () => {
          //       window.location.href = '/'
          //   },
          //   autoClose: 5000
          // })
          }
          console.log(response.data.data)
        },
        (error) => {
           
        }
    )
}, [])
  return (
    <div>
        <div className=' grid my-7 grid-cols-4 gap-4'>
        <ProjectCom
                name='Pending Withdrawal'
                count={pending}
            />
             <ProjectCom
                name='Approved Withdrawal'
                count={active}
            />
            <ProjectCom
                name='Rejected Withdrawal'
                count={rej}
            />
             <ProjectCom
                name='Total amount Withdrawal'
                count={total}
            />
        </div>
        <WithdrawalTable/>
    </div>
  )
}

export default Main