import React, { useState } from 'react'
import ButtonPlus from '../SelectValue/ButtonPlus'
import NotificationTable from './NotificationTable'
import AddNotification from './AddNotification'

function Main() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
  return (
    <div>
    <AddNotification
        open={open}
        handleClose={handleClose}
    />
        <div className=' my-5'>
            <ButtonPlus
                name='Add Notification'
                onClick={handleOpen}
            />
        </div>
        <NotificationTable/>
    </div>
  )
}

export default Main