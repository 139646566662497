import React from 'react'
import ForgetPassForm from '../../Components/ForgetPass/ForgetPass'

function ForgetPassword() {
  return (
    <div className='bg-signin-bg'>
    <div className='  grid w-10/12 md:w-8/12   mx-auto h-[100vh] place-items-center'>
   
     <ForgetPassForm/>
    </div>
    </div>
  )
}

export default ForgetPassword