import React from 'react'
import Main from '../../Components/Withdrawal/Main'

function Withdrawer() {
  return (
    <div>
        <Main/>
    </div>
  )
}

export default Withdrawer