
import React from 'react';
import {BrowserRouter, Route,  Routes} from 'react-router-dom';
import './App.css';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import cookie from 'react-cookies'
import CompleteSignup from './Pages/Siginup';
import ForgetPassword from './Pages/ForgetPass';
import ConfirmPass from './Pages/ForgetPass/ConfirmPass';
import Otp from './Pages/Otp';
import ResetPin from './Pages/Otp/ResetPin';

const Logout = React.lazy(() => import('./Pages/logout'));
class App extends React.Component {

  constructor(props) {
    super(props);
    // var token = localStorage.getItem('token');
    var tokens = cookie.load('token')
    console.log(tokens)
    if(tokens === undefined){
      this.loggedIn = false;
     
    }else{
      this.loggedIn = true;
      // setTimeout(this.logout, 100000);
     
    }
  }

  logout() {
    // localStorage.removeItem('token');
    cookie.remove("token");
    localStorage.removeItem('userdetails');
    this.loggedIn = false;
    window.location.href = '/';
  }
    render() {
    return this.loggedIn === true ? (
      <div>
      <BrowserRouter>
      <Routes>
        
        <Route path='/logout' element={ <Logout /> } />
        <Route path='*' element={ <Dashboard /> } />
      </Routes>

      
      </BrowserRouter>  
      </div>
    ): (
      <div>
      <BrowserRouter>
   
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/signup/:uid' element={<CompleteSignup/>}/>
        <Route path='/forgetpass' element={<ForgetPassword/>}/>
        <Route path='/verification' element={<Otp/>}/>
        <Route path='/reset' element={<ResetPin/>}/>
        <Route path='/comfirmpass' element={<ConfirmPass/>}/>
           <Route path='*'  element={<Login/>} />
          
      </Routes>
      </BrowserRouter>  
      </div>
    )
      ;
  }
}

export default App;
