import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useState } from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Progress from '../SelectValue/Progress';
import Switch from '@mui/material/Switch';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ImageSlider from '../ImageSlider';
import Constant from '../../Constant';
import Delete from '../SelectValue/DeleteButton';
import getServices from '../../Services/get-service';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Loading from '../SelectValue/Loading';

const drawerWidth = 650;
const label = { inputProps: { 'aria-label': 'Switch demo' } };
function ActiveProjectDrawer({open, handleClose, window, image, name,location, price, unit, duration, devName, per, target, raised, id, type }) {
    const [opens, setOpens] = useState(false)
    const handleOpens = () => setOpens(true)
    const handleCloses = () => setOpens(false)
    const[ loading, setLoading] = useState(false)
    const onSubmit = () =>{
      setLoading(true)
      getServices.getDeclineProject(id).then(
        (response) => {
          setLoading(false)
        if(response.data.status === "2000"){
          toast('Project declined succesful', {
          onClose: () => {
              window.location.href = '/projects'
          },
          autoClose: 5000
        })
        }else{
         swal(response.data.description)
        //  toast('Session Expired', {
        //   onClose: () => {
        //       window.location.href = '/'
        //   },
        //   autoClose: 5000
        // })
        }
       
        console.log(response.data.data)
        
      },
      (error) => {
          setLoading(false)
          // toast('Session Expired', {
          //     onClose: () => {
          //       window.location.href = '/'
          //     },
          //     autoClose: 5000
          //   })
      }
      )
    }
  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
       <div>
       <Delete
        open={opens}
        handleClose={handleCloses}
        onSubmit={onSubmit}
       />
       <Loading
        open={loading}
       />
         
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 text-[18px] pt-3 capitalize'> {type} project</p>
            <div className='pt-2'>
          <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/> 
            </div>  
        </div>
          <div className=' px-6 py-3'>
          <div className=' relative'>
               <ImageSlider
                imageList={image}
               />
            </div>
            <div className=' mt-4'>
                <p className=' font-[500] text-back-color2 text-[16px]'>{name}</p>
                <p className=' font-[500] text-back-color2 my-4 text-[14px] '><PlaceOutlinedIcon className=' text-primary-color'/>{location}</p>
            </div>
            <div className=' my-4 flex gap-5'>
                <div>
                    <p className=' bg-[#F5F7FA] text-[14px] font-[400] p-3 rounded-[32px] text-center'>Investment time period : {duration} days</p>
                </div>
                <div>
                    <p className='bg-[#CCF2E1] text-[14px] p-3 rounded-[32px] text-center'>NGN {Constant.FormatNumber(price) } per unit</p>
                </div>
                <div>
                    <p className='bg-[#FFDEDE] text-[14px] p-3 rounded-[32px] text-center'>{unit} Units remaining</p>
                </div>
            </div>
            <div className=' my-4'>
                <p className=' my-3 text-back-color2 font-[500] text-[16px]'>project by: {devName}</p>
                <div className='bg-[#F5F7FA] p-5 rounded-lg'>
                    <p className=' font-[400] text-[16px] text-back-color2 mb-2'>{per}% sold</p>
                    <Progress
                        per={per}
                    />
                    <div className=' my-2 flex justify-between'>
                    <p className=' text-[14px]'>Target</p>
                    <p className=' text-[14px]'>Raised</p>
                    </div>
                    <div className=' my-2 flex justify-between'>
                    <p className=' text-[16px] text-back-color2 font-[700]'>NGN {Constant.FormatNumber(target) }</p>
                    <p className=' text-[16px] text-primary-color font-[700]'>NGN {Constant.FormatNumber(raised) }</p>
                    </div>
                </div>
                <div className='bg-[#F5F7FA] mt-4 p-3 rounded-lg flex gap-4'>
                        <p className=' text-[14px] pt-2'> <PlaceOutlinedIcon  className=' text-[#1E222B]'/>Edit project details</p>
                        {/* <p className=' text-[14px]'> <PlaceOutlinedIcon  className=' text-[#1E222B]'/>Edit price per unit</p> */}
                        <p className=' text-[14px] pt-2'> <PlaceOutlinedIcon  className=' text-[#1E222B]'/>Edit pictures</p>
                        <p className='text-[14px] flex'><Switch className=' inline-block' {...label} /><span className=' pt-2'>Published</span></p>
                        <p onClick={handleOpens} className=' text-[14px] pt-2 cursor-pointer'> <DeleteOutlinedIcon className=' text-[#FF5959]'/> Remove</p>
                </div>
            </div>
          </div>
       </div>
         

        </Drawer>
    </div>
  )
}

export default ActiveProjectDrawer