import React from 'react'
import Main from '../../Components/Liquidate/Main'

function Liquidate() {
  return (
    <div>
        <Main/>
    </div>
  )
}

export default Liquidate