import React from 'react'
import Main from '../../Components/Notification/Main'

function Notification() {
  return (
    <div>
        <Main/>
    </div>
  )
}

export default Notification