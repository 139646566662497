import React from 'react'
import MainContent from '../../Components/Property/MainContent'

function Properties() {
  return (
    <div>
      <MainContent/>
    </div>
  )
}

export default Properties