import React from 'react'
import In from '../../Assets/images/in.png'
import Constant from '../../Constant'
function ProjectCom({name, count}) {
  return (
    <div className=' border border-gray-200 rounded-lg p-3'>
        <p>{name}</p>
        <div className=' mt-2 flex gap-4'>
            <div>
                <img src={In} alt='Investment'/>
            </div>
            <div>
                <p className=' text-[24px] pt-2 font-[500] text-back-color2'>{ Constant.FormatNumber(count) }</p>
            </div>
        </div>
    </div>
  )
}

export default ProjectCom