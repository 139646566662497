import React from 'react'
import {  Route, Routes } from 'react-router-dom';
import ProjectDetails from '../../Components/Projects/ProjectDetails';
import ProjectDrawer from '../../Components/Projects/ProjectDrawer';
import UploadPictures from '../../Components/Projects/UploadPictures';
import AddProperty from '../../Components/Property/AddProperty';
import PropertyDrawer from '../../Components/Property/PropertyDrawer';
import Customers from '../Customers';
import Investment from '../Investment';
import MainDashboard from '../MainDashboard';
import Projects from '../Projects';
import Properties from '../Properties';
import Savings from '../Savings';
import Settings from '../Settings';
import Users from '../Users';
import AddLandListing from '../../Components/Property/AddLandListing';
import Liquidate from '../Liquidate';
import Withdrawer from '../Withdrawal';
import Notification from '../Notification';


function Routess() {
  
  return (
    <div>
    {localStorage.getItem("userRole") === 'SUPER_ADMIN' ?
    <Routes>
          <Route path='/dashboard' element={<MainDashboard/>}/>
          <Route path='/liquidate' element={<Liquidate/>}/>
          <Route path='/notification' element={<Notification/>}/>
          <Route path='/withdrawal' element={<Withdrawer/>}/>
          <Route path='/investment' element={<Investment/>}/>
          <Route path='/projects' element={<Projects/>}>
            <Route path='newproject' element={<ProjectDrawer/>}>
            <Route path='projectdetails' element={<ProjectDetails/>}/>
            <Route path='pictures' element={<UploadPictures/>}/>
            </Route>
            {/* <Route path='active' element={<ActiveProject/>}/>
            <Route path='pending' element={<PendingProject/>}/>
            <Route path='complete' element={<CompletedProject/>}/> */}
          </Route>
          <Route path='/savings' element={<Savings/>}/>
          <Route path='/properties' element={<Properties/>}>
            <Route path='newproperty' element={<PropertyDrawer/>}>
              <Route path='property' element={<AddProperty/>}/>
              <Route path='land' element={<AddLandListing/>}/>
            </Route>
          </Route>
          <Route path='/customers' element={<Customers/>}/>
          <Route path='/users' element={<Users/>}/>
          <Route path='/settings' element={<Settings/>}/>
        </Routes>:
        localStorage.getItem("userRole") === 'OPERATION_MANAGER' ?
        <Routes>
          <Route path='/dashboard' element={<MainDashboard/>}/>
          <Route path='/notification' element={<Notification/>}/>
          <Route path='/investment' element={<Investment/>}/>
          <Route path='/projects' element={<Projects/>}>
            <Route path='newproject' element={<ProjectDrawer/>}>
            <Route path='projectdetails' element={<ProjectDetails/>}/>
            <Route path='pictures' element={<UploadPictures/>}/>
            </Route>
            {/* <Route path='active' element={<ActiveProject/>}/>
            <Route path='pending' element={<PendingProject/>}/>
            <Route path='complete' element={<CompletedProject/>}/> */}
          </Route>
          <Route path='/savings' element={<Savings/>}/>
          <Route path='/properties' element={<Properties/>}>
            <Route path='newproperty' element={<PropertyDrawer/>}>
              <Route path='property' element={<AddProperty/>}/>
              <Route path='land' element={<AddLandListing/>}/>
            </Route>
          </Route>
          <Route path='/customers' element={<Customers/>}/>
          <Route path='/users' element={<Users/>}/>
          <Route path='/settings' element={<Settings/>}/>
        </Routes>:
        localStorage.getItem("userRole") === 'PROJECT_MANAGER' ?
        <Routes>
          <Route path='/dashboard' element={<MainDashboard/>}/>
          <Route path='/notification' element={<Notification/>}/>
          <Route path='/investment' element={<Investment/>}/>
          <Route path='/projects' element={<Projects/>}>
            <Route path='newproject' element={<ProjectDrawer/>}>
            <Route path='projectdetails' element={<ProjectDetails/>}/>
            <Route path='pictures' element={<UploadPictures/>}/>
            </Route>
            {/* <Route path='active' element={<ActiveProject/>}/>
            <Route path='pending' element={<PendingProject/>}/>
            <Route path='complete' element={<CompletedProject/>}/> */}
          </Route>
          <Route path='/properties' element={<Properties/>}>
            <Route path='newproperty' element={<PropertyDrawer/>}>
              <Route path='property' element={<AddProperty/>}/>
              <Route path='land' element={<AddLandListing/>}/>
            </Route>
          </Route>
          <Route path='/customers' element={<Customers/>}/>
          <Route path='/settings' element={<Settings/>}/>
        </Routes>:
        localStorage.getItem("userRole") === 'FINANCE_MANAGER' ?
        <Routes>
          <Route path='/dashboard' element={<MainDashboard/>}/>
          <Route path='/liquidate' element={<Liquidate/>}/>
          <Route path='/withdrawal' element={<Withdrawer/>}/>
          <Route path='/investment' element={<Investment/>}/>
         
          <Route path='/savings' element={<Savings/>}/>
       
          <Route path='/settings' element={<Settings/>}/>
        </Routes>:
        localStorage.getItem("userRole") === 'OPERATOR' ?
        <Routes>
          <Route path='/dashboard' element={<MainDashboard/>}/>
          <Route path='/projects' element={<Projects/>}>
            <Route path='newproject' element={<ProjectDrawer/>}>
            <Route path='projectdetails' element={<ProjectDetails/>}/>
            <Route path='pictures' element={<UploadPictures/>}/>
            </Route>
            {/* <Route path='active' element={<ActiveProject/>}/>
            <Route path='pending' element={<PendingProject/>}/>
            <Route path='complete' element={<CompletedProject/>}/> */}
          </Route>
          <Route path='/properties' element={<Properties/>}>
            <Route path='newproperty' element={<PropertyDrawer/>}>
              <Route path='property' element={<AddProperty/>}/>
              <Route path='land' element={<AddLandListing/>}/>
            </Route>
          </Route>
          <Route path='/settings' element={<Settings/>}/>
        </Routes>:
        <Routes>
        <Route path='/dashboard' element={<MainDashboard/>}/>
        </Routes>
    }
      
   

        
    </div>
  )
}

export default Routess