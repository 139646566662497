import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ActiveProject from './ActiveProject';
import PendingProject from './PendingProject';
import CompletedProject from './CompletedProject';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Sections() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}
        textColor="#1E222B"
        TabIndicatorProps={{style: {background:'#00BC67'}}}
        
        indicatorColor="secondary"
         onChange={handleChange} 
         aria-label="basic tabs example"
         >
          <Tab label="Active projects"
           {...a11yProps(0)} />
          <Tab label="Pending approval" {...a11yProps(1)} />
          <Tab label="Completed projects" {...a11yProps(2)} />

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <ActiveProject/>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <PendingProject/>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <CompletedProject/>
      </TabPanel>
    </Box>
  );
}