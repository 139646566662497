import React, { useEffect, useState } from 'react'
import ProjectCom from '../Projects/ProjectCom'
import LiquidateTable from './LiquiTable'
import getServices from '../../Services/get-service'

function Main() {
  const [pending, setPending] = useState('0')
  const [active, setActive] = useState('0')
  const [rej, setRejec] = useState('0')
  useEffect(() =>{
    getServices.getAllCount().then(
        (response) => {
           
          if(response.data.status === "2000"){
            setActive(response.data.data.countSuccessLiquidate)
            setPending(response.data.data.countPendingLiquidate)
            setRejec(response.data.data.countDeclineLiquidate)
          }else{
          //  swal(response.data.description)
          //  toast('Session Expired', {
          //   onClose: () => {
          //       window.location.href = '/'
          //   },
          //   autoClose: 5000
          // })
          }
          console.log(response.data.data)
        },
        (error) => {
           
        }
    )
}, [])
  return (
    <div>

        <div className=' my-5 grid grid-cols-3 gap-6'>
        <ProjectCom
                name='Pending liquidate'
                count={pending}
            />
             <ProjectCom
                name='Completed liquidate'
                count={active}
            />
            <ProjectCom
                name='Rejected liquidate'
                count={rej}
            />
        </div>
        <LiquidateTable/>
    </div>
  )
}

export default Main