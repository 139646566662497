import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react'
import { Link, Outlet } from 'react-router-dom';
const drawerWidth = 650;

function ProjectDrawer({ handleClose, window}) {
    
  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={true}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
       <div>
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 text-[18px] pt-3'>Add new propject</p>
            <div className='pt-2'>
          <Link to='/projects'><CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/></Link>  
            </div>  
        </div>
       
       </div>
         <Outlet/>

        </Drawer>
    </div>
  )
}

export default ProjectDrawer