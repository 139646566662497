import React, { useEffect, useState } from 'react'
import { Link,  Outlet } from 'react-router-dom'
import ButtonPlus from '../SelectValue/ButtonPlus'
import ProjectCom from './ProjectCom'
import Sections from './Sections'
import getServices from '../../Services/get-service'

function MainPage() {
    const [active, setActive] = useState("0")
    const [pending, setPending] = useState('0')
    const [complected, setCompleted] = useState('0')

    useEffect(() =>{
            getServices.getAllCount().then(
                (response) => {
                   
                  if(response.data.status === "2000"){
                    setActive(response.data.data.totalSumActiveProject)
                    setPending(response.data.data.totalPendingProject)
                    setCompleted(response.data.data.totalSumCompletedProject)
                  }else{
                  //  swal(response.data.description)
                  //  toast('Session Expired', {
                  //   onClose: () => {
                  //       window.location.href = '/'
                  //   },
                  //   autoClose: 5000
                  // })
                  }
                  console.log(response.data.data)
                },
                (error) => {
                   
                }
            )
    }, [])
  return (
    <div>

    <Link to='newproject/projectdetails'>
    <ButtonPlus
            name='Add new project'
           
        />
    </Link>
       
        
        <div className=' my-6 grid md:grid-cols-3 gap-4'>
            <ProjectCom
                name='Active projects'
                count= {active === 'nu' ? '0' : active}
            />
            <ProjectCom
                name='Pending approval'
                count= {pending === 'nu' ? '0': pending}
            />
            <ProjectCom
                name='Completed projects'
                count= {complected === 'nu' ? '0' : complected}
            />
        </div>
        <div className=' mt-10'>
            <Sections/>
            <Outlet/>
        </div>
    </div>
  )
}

export default MainPage