
import Dropzone from 'react-dropzone';
import Cloud from '../Assets/images/cloud.svg'
import { DeleteOutline } from '@mui/icons-material';
const ImageUploader = ({images, setImages}) => {
  

  const handleDrop = (acceptedFiles) => {
    const maxSize = 1024 * 1024; // 1MB
    const updatedImages = acceptedFiles.filter((file) => {
        return file.size <= maxSize && file.type.startsWith('image/');
      });
      setImages((prevImages) => [...prevImages, ...updatedImages]);
  };

  const handleRemove = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // const handleSubmit = async () => {
  //   // Prepare form data
  //   const formData = new FormData();
  //   images.forEach((image) => {
  //     formData.append('images', image);
  //   });

  //   try {
  //     // Make API request
  //     const response = await axios.post('/api/upload', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     // Handle response
  //     console.log(response.data); // Modify this to handle the response from the API

  //     // Clear uploaded images
  //     setImages([]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div>
      <Dropzone onDrop={handleDrop} accept="image/*" multiple>
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone" {...getRootProps()}>
            <input className=' border w-full ' {...getInputProps()} accept="image/*"  />
            <p className=' border-dashed border-2  border-gray-200 rounded-lg p-3 py-6 '> <img src={Cloud} className=' inline-block' alt='Cloud'/> Drag and drop some files here.   or <span className=' p-3  bg-[#E6E6E7] cursor-pointer'>Tap here to choose a file</span> </p>
          </div>
        )}
      </Dropzone>
      {images.length > 0 && (
        <div>
          <h4 className=' my-3 text-[16px] font-bold'>Selected Images:</h4>
          <div className=' flex gap-3 mx-auto '>
          {images.map((image, index) => (
            <div className=' relative' key={index}>
              <img src={URL.createObjectURL(image)}  alt="uploaded" />
              <button className=' absolute top-0 right-0 text-primary-color' onClick={() => handleRemove(index)}><DeleteOutline/></button>
            </div>
          ))}
          </div>
         
        </div>
      )}
      {/* <button onClick={handleSubmit}>Upload Images</button> */}
    </div>
  );
};

export default ImageUploader;
