import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import swal from 'sweetalert';
import postService from '../../Services/post-service';
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield';
import Loading from '../SelectValue/Loading';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};
function SetRate({ open, handleClose}) {
    const [rate, setRate] = useState()
    const [duration, setDuration] = useState('')
    const [loading, setLoading] = useState(false)
    const onSubmit = (e) =>{
            e.preventDefault()
            setLoading(true)
            postService.addRate(rate, duration).then(
                (response) => {
                    console.log(response.data)
                   setLoading(false)
                   if(response.data.status === '2000'){
                  
                   
                        swal("Savings rate set").then((result) => {
                          return   window.location.reload()
                        })
                    // localStorage.setItem('token',response.data.data.token)
                   
                   }else{
                    swal(response.data.description)
                   
                   }
                   
                  },
                  (error) => {
                   // setLoading(false)
                      return  swal('Error')
                      .then((value) => {
                        window.location.reload()
                      });
                    }
              )
    }
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
            open={loading}
          />
            <div className='  '>
                <div className=''>
                    <div className=' flex mb-6 justify-end'>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                    <div>
                        <form onSubmit={onSubmit}>
                        <div>
                            <label className=' mb-3 block'>Select Duration</label>
                        <select 
                            value={duration} 
                            onChange={(e) => setDuration(e.target.value)} 
                            required 
                            className=' p-4 w-full outline-0 rounded-lg border bg-white '>
                                <option value={'90'}>3 months</option>
                                <option value={'180'}>6 months</option>
                                <option value={'270'}>9 months</option>
                                <option value={'365'}>12 months</option>
                            </select>
                            </div>
                            <div className=' my-5'></div>
                            <NonnegativeInputField
                              label={"Rate"}
                              valuee={rate}
                              onChange={setRate}
                            />
                            <div className=' mt-5 flex justify-end'>
                                <Button
                                    name={"Submit"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
          </Box>
        </Fade>
      </Modal>
  )
}

export default SetRate