import React from 'react'
import SigupForm from './SignupForm'

function CompleteSignup() {
  return (
    <div className='bg-signin-bg'>
    <div className='  grid w-10/12 md:w-8/12   mx-auto h-[100vh] place-items-center'>
        <SigupForm/>
    </div>
     </div>
  )
}

export default CompleteSignup