const phoneValidation= /^([\s\(\)\-]*\d[\s\(\)\-]*){8}$/
const emailValidation= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordValidation = /^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$/
const passwordValidationMessage = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/
const FormatNumber = (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
    });
  
    return formatter.format(number);
  };
const Constant = {
    phoneValidation,
    emailValidation,
    passwordValidation,
    passwordValidationMessage,
    phoneRegExp,
    FormatNumber
}

export default Constant