import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert';
import Logo from '../../Assets/images/Zlogo.png'
import { useParams } from 'react-router-dom'
import Loading from '../../Components/SelectValue/Loading';
import FormikControl from '../../Components/SelectValue/FormikControl';
import Button from '../../Components/SelectValue/Button';
import postService from '../../Services/post-service';
import { Divider } from '@mui/material';
// import Constant from '../../Constant'
function SigupForm() {
  const [loading, setLoading] = useState(false)
  const {uid} = useParams()
    const initialValues = { 
        phone: '',
        password: '',
        pin: '',

      }
      const validationSchema = Yup.object({
        pin: Yup.string()
        .matches(/^\d{4}$/, 'PIN must be a 4-digit number')
        .required('PIN is required'),
        password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/,
      'Password must contain at least one letter, one digit, and one special character'
    ),
    phone: Yup.string()
    .required('Phone number is required')
    .matches(/^\d{11}$/, 'Phone number must be exactly 11 digits'),
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {phone, password, pin} = values
        postService.userCompleteSignup(phone, password,pin,uid).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
                swal("Signup Completed").then((result) => {
                    return   window.location.href = '/'
                  })

              // localStorage.setItem('token',response.data.data.token)
             
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  swal('Signup Failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
        // navigate('/dashboard')
      }

  return (
    <div className=' bg-white shadow-lg p-16 rounded-[16px]'>
    <Loading
      open={loading}
    />
        <div className=' '>
        <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
            <p className=' mt-6 text-[18px] text-back-color2 mb-5 md:text-[40px] font-[500]'>Admin complete signup</p>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                type='text'
                label='Phone number'
                name='phone'
              />  
               
               <FormikControl
                control='password'
                label='Password'
                name='password'
              /> 
              
              <div className=' mt-4'>
              <Divider/>
              <p className=' my-3'>Enter your 4 digit pin  </p>
              <FormikControl
                control='input'
                label='Pin'
                name='pin'
                maxLength="4"
              /> 
              </div>
               
             
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Submit'
              />
              </div>
              
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default SigupForm