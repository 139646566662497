import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';

import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function Delete({ open, handleClose, onSubmit}) {
  
   
  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className='  '>
                <div className=''>
                    <div className=' flex mb-6 justify-end'>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                    
                    <p className=' mt-7 text-[18px] font-[500]  text-back-color2'>Are you sure?</p>
                    <p className=' mt-4 my-10 text-[16px] text-[#808285] font-[400]'>You are about to delete </p>
                   <div className=' mt-4 grid md:grid-cols-2 gap-3'>
                
                    <div className=' '>
                        <button onClick={onSubmit} className=' bg-white  w-full text-[16px] text-[#FF5959] rounded-lg border border-gray-500 p-2'>
                        Yes
                        </button>
                        
                    </div>
                    <div className=' grid grid-cols-1'>
                    <Button
                            name='Cancel'
                            onClick={handleClose}
                        />
                    </div>
                    
                   </div>
                </div>
                
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}