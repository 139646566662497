import axios from "axios";
import authHeader from "./AuthHeader";
import cookie from 'react-cookies'
const tokens = cookie.load('ltoken')
const getAllBank = () => {
    return axios.get( "/api/get-banks");
}
const getUserBanks = () => {
    return axios.get( "/api/user-bankDetail", { headers: authHeader() });
}
const getTransactionHistory = () => {
    return axios.get( "/api/transaction-history", { headers: authHeader() });
}
const refreshToken = () => {
    return axios.get( "/api/refresh-token-admin", { headers: authHeader() });
}
const verifyBankAccount = (accNum, bankCode) => {
    return axios.get( `/api/verify-accountNumber?accountNumber=${accNum}&bankCode=${bankCode}`, { headers: authHeader() });
}
const getNextOfKin = () =>{
    return axios.get( "/api/next-of-kin", { headers: authHeader() });
}
const getAllProject = (location, maxPrice, minPrice,projectName, projectType) =>{
    return axios.get( `/api/all-projects?location=${location}&maxPrice=${maxPrice}&minPrice=${minPrice}&projectName=${projectName}&projectType=${projectType}`, { headers: authHeader() });
}
const getSingleProject = (id) =>{
    return axios.get( `/api//listing?id=${id}`, { headers: authHeader() });
}
const getAllInvestment = () =>{
    return axios.get( `/api/get-investment`, { headers: authHeader() });
}
const getMonthlyInvestmentReport= () =>{
    return axios.get( `/api/monthly-investment-report`, { headers: authHeader() });
}
const getApprovedProjectRequest = (id) =>{
    return axios.get( `/api/approve-project?projectId=${id}`, { headers: authHeader() });
}
const getDeclinedProjectRequest = (id) =>{
    return axios.get( `/api/decline-project?projectId=${id}`, { headers: authHeader() });
}
const getWeeklyInvestmentReport = () =>{
    return axios.get( `/api/weekly-investment-report`, { headers: authHeader() });
}
const getCustomInvestmentReport = (startDate, endDate) =>{
    return axios.get( `/api/custom-investmentList-report?endDate=${endDate}&startDate=${startDate}`, { headers: authHeader() });
}
const getCustomInvestmentList = (startDate, endDate) =>{
    return axios.get( `/api/custom-investmentList-report?endDate=${endDate}&startDate=${startDate}`, { headers: authHeader() });
}
const getWeeklyInvestmentList = () =>{
    return axios.get( `/api/weekly-investmentList-report`, { headers: authHeader() });
}
const getMonthlyInvestmentList = () =>{
    return axios.get( `/api/monthly-investment-report`, { headers: authHeader() });
}

const getUserInvestment = () =>{
    return axios.get( `/api/monthly-investment-report`, { headers: authHeader() });
}
const getAllUsers = () =>{
    return axios.get( `/api/get-users`, { headers: authHeader() });
}
const getAllUser = () =>{
    return axios.get( `/api/get-user`, { headers: authHeader() });
}
const getAllprojectListed = (startDate, endDate) =>{
    return axios.get( `/api/all-projects-listed?endDate=${endDate}&startDate=${startDate}`, { headers: authHeader() });
}
const getDeclineProject = (id) =>{
    return axios.get( `/api/decline-project?id=${id}`, { headers: authHeader() });
}
const getAcceptProject = (id) =>{
    return axios.get( `/api/approve-project?id=${id}`, { headers: authHeader() });
}
const getProjectListed = (startDate, endDate) =>{
    return axios.get( `/api/return-all-properties?endDate=${endDate}&startDate=${startDate}`, { headers: authHeader() });
}
const getAllCount = () =>{
    return axios.get( `/api/count-apis`, { headers: authHeader() });
}
const getAllLiqui = () =>{
    return axios.get( `/api/liquidation-request`, { headers: authHeader() });
}
const approveLiqui =(id) =>{
    return axios.get( `/api/approve-liquidation-request?investmentId=${id}`, { headers: authHeader() });
}
const deApproveLiqui=(id) =>{
    return axios.get( `/api/decline-liquidation-request?investmentId=${id}`, { headers: authHeader() });
}
const getWithdrawerList=() =>{
    return axios.get( `/api/list-transfers`, { headers: authHeader() });
}
const getNotification=() =>{
    return axios.get( `/api/get-notifications`, { headers: authHeader() });
}

const get2faSetup=() =>{
    return axios.get( `/api/setup-2fa`, { headers: {  
        
        Authorization: tokens 
   } });
}
const getAdminUsers=() =>{
    return axios.get( `/api/get-all-users`, { headers: authHeader() });
}

const getRole=() =>{
    return axios.get( `/api/get-roles`, { headers: authHeader() });
}

const getDashboardCount=(startDate, endDate) =>{
    return axios.get( `/api/custom-investment-report?endDate=${endDate}&startDate=${startDate}`, { headers: authHeader() });
}

const getServices = {
    getAllBank,
    getDashboardCount,
    getAdminUsers,
    getRole,
    verifyBankAccount,
    getUserBanks,
    getTransactionHistory,
    refreshToken,
    getNextOfKin,
    getAllProject,
    getSingleProject,
    getAllInvestment,
    getMonthlyInvestmentReport,
    getApprovedProjectRequest,
    getDeclinedProjectRequest,
    getWeeklyInvestmentReport,
    getCustomInvestmentReport,
    getCustomInvestmentList,
    getWeeklyInvestmentList,
    getMonthlyInvestmentList,
    getUserInvestment,
    getAllUsers,
    getAllUser,
    getAllprojectListed,
    getAcceptProject,
    getDeclineProject,
    getProjectListed,
    getAllCount,
    getAllLiqui,
    approveLiqui,
    deApproveLiqui,
    getWithdrawerList,
    getNotification,
    get2faSetup
}

export default getServices;
