import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import swal from 'sweetalert';
import Pin from '../SelectValue/Pins';
import postService from '../../Services/post-service';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function DeActivateModal({ open, handleClose, id, status}) {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [pin, setPin] = useState('')
    const handleClo = () => setShow(false)
    const onSubmit = (e) =>{
            e.preventDefault()
            if(status ==='de'){
                setLoading(true)
                postService.deactivateAdminUser(id, pin).then(
                    (response) => {
                      setLoading(false)
                    if(response.data.status === "2000"){
                      swal("Admin deactivated!").then(
                        response =>{
                            window.location.reload()
                        }
                      )
                      console.log(response.data.data)
                    }else{
                        swal(response.data.description).then(
                            response =>{
                                window.location.reload()
                            }
                          )
                    }
                   
                    console.log(response.data.data)
                    
                  },
                  (error) => {
                      setLoading(false)
                      swal(error.message)
                  }
                  )
            }else{
                setLoading(true)
                postService.activateAdminUser(id, pin).then(
                    (response) => {
                      setLoading(false)
                    if(response.data.status === "2000"){
                      swal("Admin activated!").then(
                        response =>{
                            window.location.reload()
                        }
                      )
                      console.log(response.data.data)
                    }else{
                        swal(response.data.description).then(
                            response =>{
                                window.location.reload()
                            }
                          )
                    }
                   
                    console.log(response.data.data)
                    
                  },
                  (error) => {
                      setLoading(false)
                      swal(error.message)
                  }
                  )
            }
    }

    const onVerify = (e) =>{
       e.preventDefault()
       setShow(true)
    }
   
  return (
    <div>
      <Pin
      open={show}
      handleClose={handleClo}
      loading={loading}
      pin={pin}
      setPin={setPin}
      onSubmit={onSubmit}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          {/* <Loading
            open={loading}
          /> */}
            <div className='  '>
                <div className=''>
                    <div className=' flex mb-6 justify-end'>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                    
                    <p className=' mt-7 text-[18px] font-[500]  text-back-color2'>Are you sure? </p>
                    <p className=' mt-4 my-10 text-[16px] text-[#808285] font-[400]'>You are about to {status === 'de' ? "Deactivate" : 'Activate'} a user  </p>
                   <div className=' mt-4 grid md:grid-cols-2 gap-3'>
                
                    <div className=' '>
                        <button onClick={onVerify} className=' bg-white  w-full text-[16px] text-[#FF5959] rounded-lg border border-gray-500 p-2'>
                        Yes
                        </button>
                        
                    </div>
                    <div className=' grid grid-cols-1'>
                    <Button
                            name='Cancel'
                            onClick={handleClose}
                        />
                    </div>
                    
                   </div>
                </div>
                
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}