import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../SelectValue/Button';
import { Divider, Drawer } from '@mui/material';
import Pin from '../SelectValue/Pins';
import postService from '../../Services/post-service';
import swal from 'sweetalert';
import Inputtt from '../SelectValue/Inputtt';
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield';
import ImageUploader from '../Dropdown';
const drawerWidth = 650;

function AddInvestment({open, handleClose}) {
    const [loading, setLoading] = useState(false)
    const [opens, setOpens] = useState(false)
    const [contact, setContact] = useState()
    const [priceperunit, setPricePerUnit] = useState()
    const [location, setLocation] = useState()
    const [des, setDes] = useState()
    const [duration, setDuration] = useState()
    const [link, setLink] = useState('')
    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [rate, setRate] = useState('')
    const [totalunit, setTotalunit] = useState('')
    const [pin, setPin] = useState('')
    const [images, setImages] = useState([]);
    const [earlyExist, setEarlyExist] = useState('')

    
      
      const onSubmit = (e) => {
       e.preventDefault()
          if(images.length > 0){
            setOpens(true)
          }else{
            swal("Select an image")
          }
        
       
      }
    

      const finalSubmit = (e) =>{
        e.preventDefault()
            
        setLoading(true)
         postService.uploadProject(contact,priceperunit,location,des,duration,link,name,type,rate,totalunit,pin,images,earlyExist).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
                swal(response.data.description).then(() =>{
                  window.location.reload()
                })
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                
              }
        )
      
      }
  
  return (
    <div>
    <Pin
        open={opens}
        loading={loading}
        pin={pin}
        setPin={setPin}
        onSubmit={finalSubmit}
        handleClose={() => setOpens(false)}
    />
          <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
      
       <div className=' px-4'>
        <div className=' flex my-4 justify-between'>
            <h1 className=' text-2xl font-bold text-back-color2'>Add investment</h1>
            <CloseIcon onClick={handleClose}/>
        </div>
        <Divider/>
        <div className=' px-5 pt-3'>
           
            <div className='  mt-5'>
            <form onSubmit={onSubmit}>
                <div className='grid  my-4'>
                <Inputtt
                        label='Title'
                        value={name}
                        onChange={setName}
                    />
                </div>
                <p className=' font-[500] mb-4 text-[#44474E] text-[14px]'> <span className=' font-[800] text-back-color2 text-[14px]'>Note:</span>  Do NOT add location information to the listing title</p>
                <Divider/>
                <div className='grid  my-4'>
                <Inputtt
                        label='Location'
                        value={location}
                        onChange={setLocation}
                    />
                </div>
                <div className=' grid  my-4 '>
                    <label className=' mb-3 block'>Description</label>
                    <textarea
                    minLength={7}
                        className=' border p-4 outline-0 rounded-lg '
                        value={des}
                        required
                        onChange={(e) => setDes(e.target.value)}
                    />
                </div>
                <div className='grid  my-4'>
                <Inputtt
                        label='Contact'
                        value={contact}
                        onChange={setContact}
                    />
                </div>
                <div className=' grid sm:grid-cols-2 gap-3  my-4 '>
                
                    <NonnegativeInputField
                        label='Price per unit'
                        value={priceperunit}
                        onChange={setPricePerUnit}
                    />
                    <NonnegativeInputField
                        label='Rate'
                        value={rate}
                        onChange={setRate}
                    />
                </div>
                <div>
                <NonnegativeInputField
                        label='Total unit'
                        value={totalunit}
                        onChange={setTotalunit}
                    />
                </div>

                <div className=' grid sm:grid-cols-2 gap-3  my-4 '>
                
                <div className=''>
            <label className=' mb-3 block'>Duration</label>
            <select required className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg' value={duration} onChange={(e) => setDuration(e.target.value)}>
              <option value={''}>Select duration</option>
              <option value={'90'}>3 months</option>
              <option value={'180'}>6 months</option>
              <option value={'365'}>12 months</option>
            </select>
            </div>

            <div className=''>
            <label className=' mb-3 block'>Early exist</label>
            <select required className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg' value={earlyExist} onChange={(e) => setEarlyExist(e.target.value)}>
              <option value={''} >Select early exist</option>
              <option value={'90'}>3 months</option>
              <option value={'180'}>6 months</option>
              <option value={'365'}>12 months</option>
            </select>
            </div>
            <div>
            <label className=' block mb-2'>Project Youtube link</label>
                    <input 
                      className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg'
                      placeholder={'www.youtube.com'}
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      text={type}
                  />
            </div>
                 
                  
            </div>
            
            <div className=' my-4 mb-8'>
            <label className=' mb-3 block'>Investment type</label>
            <select required className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg ' value={type} onChange={(e) => setType(e.target.value)}>
              <option value='' >Select type</option>
              <option value={'land'}>Land banking</option>
              <option value={'property'}>Property banking</option>
            </select>
            </div>

                <ImageUploader
                    images={images}
                    setImages={setImages}
                />
                 <div className=' flex mt-8 justify-end'>
                    <Button
                        name='Save continue'
                    />
             </div>
            </form>
            </div>
        </div>
       </div>
      </Drawer>
    </div>
  )
}

export default AddInvestment