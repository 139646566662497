import  React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import PendingProjectDrawer from './PendingProjectDrawer';
import Button from '../SelectValue/Button';
import DatePicker from 'react-datepicker';
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import { format, parseISO } from 'date-fns';
import Loading from '../SelectValue/Loading';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  


export default function PendingProject() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const[project, setProject] = useState([])
  const [loading, setLoading] = useState(false)
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twoWeekAgo = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
  const [click, setClick] = useState(false)
  const [fromDate, setFromDate] = useState(oneWeekAgo);
  const [toDate, setToDate] = useState(currentDate);
const isDateValid = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero for comparison
  return date <= today;
};
useEffect(() =>{
  const newTodate= toDate.toISOString()
  const newFromdate= fromDate.toISOString()
  setLoading(true)
    getServices.getAllprojectListed(newFromdate, newTodate).then(
      (response) => {
        setLoading(false)
      if(response.data.status === "2000"){
        setProject(response.data.data)
        console.log(response.data.data)
      }else{
        swal(response.data.description)
      //  toast('Session Expired', {
      //   onClose: () => {
      //       window.location.href = '/'
      //   },
      //   autoClose: 5000
      // })
      }
     
      console.log(response.data.data)
      
    },
    (error) => {
        setLoading(false)
        // toast('Session Expired', {
        //     onClose: () => {
        //       window.location.href = '/'
        //     },
        //     autoClose: 5000
        //   })
    }
    )
},[fromDate, toDate])
const filteredData = project.filter(item => item.projectStatus === 'pending');
  return (
    <>
    <PendingProjectDrawer
        open={open}
        handleClose={handleClose}
    />
    <Loading
      open={loading}
    />
     <div className=' mb-5 grid grid-cols-2 md:grid-cols-8 gap-4'>
                <div className=' pt-2 col-span-3'>
                    <p className=' text-[20px] text-black font-normal'>Overview</p>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(oneWeekAgo)
                      setClick(false)
                      }}  
                    className={!click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 7 days</button>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(twoWeekAgo)
                      setClick(true)
                      }} 
                    className={click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 14 days</button>
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'> From:</p>
                  <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'> To:</p>
               <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={toDate}
                onChange={(date) => setToDate(date).toISOString()}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div>
                    <Button
                        name='Search'
                    />
                </div>
            </div>
    <Paper className=' mt-6' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Developer</StyledTableCell>
            <StyledTableCell align="right">Project type</StyledTableCell>
            <StyledTableCell align="right">Date submittedt</StyledTableCell>
            <StyledTableCell align="right">Project cost</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {row.createdBy}
              </TableCell>
              <TableCell align="right">{row.projectType}</TableCell>
              <TableCell align="right">{row.startDate === null ? row.startDate : format(parseISO(row.startDate), 'MMM d yyyy')}</TableCell>
              <TableCell align="right">{row.pricePerUnit}</TableCell>
              <TableCell align="right" className=' text-primary-color '><PendingOutlinedIcon onClick={handleOpen} /></TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        <div className=' my-3'>
          <p>{ filteredData.length === 0 && <p className=' text-red-600  text-center'>No pending project found</p>}</p>
      </div>
    </Paper>
    </>
  );
}