import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'MON',
    balance: 4000,
    payout: 2400,
    amt: 2400,
  },
  {
    name: 'TUE',
    balance: 3000,
    payout: 1398,
    amt: 2210,
  },
  {
    name: 'WED',
    balance: 2000,
    payout: 9800,
    amt: 2290,
  },
  {
    name: 'THUR',
    balance: 2780,
    payout: 3908,
    amt: 2000,
  },
  {
    name: 'FRI',
    balance: 1890,
    payout: 4800,
    amt: 2181,
  },
  {
    name: 'SAT',
    balance: 2390,
    payout: 3800,
    amt: 2500,
  },
  {
    name: 'SUN',
    balance: 3490,
    payout: 4300,
    amt: 2100,
  },
];

export default class Chart extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/customized-legend-event-l19fo';

  state = {
    opacity: {
      balance: 1,
      payout: 1,
    },
  };

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.5 },
    });
  };

  handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  render() {
    const { opacity } = this.state;

    return (
      <div className=' w-full rounded-lg border border-gray-200 p-3'>
      <div className=' flex my-6 justify-between'>
       <div> <p className=' ml-6 text-[20px] text-[400]'>Inflow & Outflow of cash</p></div>
       <div className=' flex gap-4 mr-5'>
            <p className=' border-b-2 border-primary-color'>Daily</p>
            <p>Weekly</p>
            <p>Monthly</p>
            <p>Yearly</p>
       </div>
      </div>
      <div className=' ml-6 gap-4 flex'>
        <div className=' mb-4 flex'>
        <div className=' mt-1 mr-2'>
        <p className=' p-2 rounded-full bg-primary-color'></p>
        </div>
          
            <p>Amount invested: NGN 5,000,000</p>
        </div>
        <div className=' mb-4 flex'>
        <div className=' mt-1 mr-2'>
        <p className=' p-2 rounded-full bg-[#F7941D]'></p>
        </div>
          
            <p>Payout: NGN 5,000,000</p>
        </div>
      </div>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />
            <Line type="monotone" dataKey="payout" strokeOpacity={opacity.pv} stroke="#F7941D" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="balance" strokeOpacity={opacity.uv} stroke="#00BC67" />
          </LineChart>
        </ResponsiveContainer>
       
      </div>
    );
  }
}