import React from 'react'

function Inputtt({placeholder, value, label, type, onChange}) {
  return (
    <div className=' w-full'>
    <label className=' block mb-2'>{label}</label> 
        <input 
            className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg'
            placeholder={placeholder}
            value={value}
            required
            onChange={(e) => onChange(e.target.value)}
            text={type}
        />
    </div>
  )
}

export default Inputtt