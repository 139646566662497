import React from 'react'
import MainPage from '../../Components/Projects/MainPage'

function Projects() {
  return (
    <div>
      <MainPage/>
    </div>
  )
}

export default Projects