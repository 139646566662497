import React, { useState } from 'react'
import Button from '../SelectValue/Button'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../Components/SelectValue/FormikControl';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import Loading from '../SelectValue/Loading';
import postService from '../../Services/post-service';
import swal from 'sweetalert';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function PasswordUpdate({ open, handleClose}) {
   
   const [loading, setLoading] = useState(false)

   const initialValues = { 
    phone: '',
    password: '',
    pin: '',

  }
  const validationSchema = Yup.object({
   
    password: Yup.string()
.required('Old password is required')
.matches(
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/,
  'Password must contain at least one letter, one digit, and one special character'
),
newpassword: Yup.string()
.required('New password is required')
.matches(
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/,
  'Password must contain at least one letter, one digit, and one special character'
),
        
  })
  const onSubmit = values => {
    console.log('Form data', values)
    console.log('Saved data', JSON.parse(JSON.stringify(values)))
    setLoading(true)
    const { password, newpassword} = values
    postService.changePassword(password, newpassword).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
            swal("Password changed successfully!").then((result) => {
                return   window.location.reload()
              })

          // localStorage.setItem('token',response.data.data.token)
         
         }else{
          swal(response.data.description)
         
         }
         
        },
        (error) => {
          setLoading(false)
            return  swal('Password change failed')
            .then((value) => {
              window.location.reload()
            });
          }
    )
    // navigate('/dashboard')
  }
  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
            open={loading}
          />
            <div className=' h-[60vh] md:mt-5'>
                <div className=' mt-6 flex justify-end mb-3'>
                    
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <div >
                <div className=''>
    <div className=' mt-10 '>
      
       <p className=' mb-3 text-back-color2'>Enter your Zouse Password</p>
       {/* <p className=' mb-3 text-[#808285]'>Enter your 4-Digit PIN to confirm transaction</p> */}
        <div className='  mt-5'>

        <div >
        <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>  
               <FormikControl
                control='password'
                label='Old password'
                name='password'
              /> 
              <FormikControl
                control='password'
                label='New paassword'
                name='newpassword'
              /> 
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Submit'
              />
              </div>
              
              
        </Form>
        )}
            </Formik>
            </div>
         
          </div>
      
        </div>
    </div>
</div>
         
          </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}