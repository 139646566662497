import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ButtonPlus from '../SelectValue/ButtonPlus';
import { format, parseISO } from 'date-fns';
import AddAdmin from './AddAdmin';
import getServices from '../../Services/get-service';
import Loading from '../SelectValue/Loading';
import ChangeRole from './ChangeRole';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeActivateModal from './DeActiviteModal';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
//   const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//     // hide last border
//     '&:last-child td, &:last-child th': {
//       border: 0,
//     },
//   }));
  



export default function UserTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [email, setEmail] = useState('')
  const [user, setUsers] = useState([])
  const [id, setId] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
    const [open, setOpen]= useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [open2, setOpen2]= useState(false)
    const handleOpen2 = (e, s) => {
      setOpen2(true)
      setId(e)
      setStatus(s)
    }
    const handleClose2 = () => setOpen2(false)

    const [open1, setOpen1]= useState(false)
    const handleOpen1 = (e) => {
      setOpen1(true)
      setEmail(e)
    }
    const handleClose1 = () => setOpen1(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() =>{
    setLoading(true)
      getServices.getAdminUsers().then(
        (response) => {
          setLoading(false)
        if(response.data.status === "2000"){
          setUsers(response.data.data.reverse())
          console.log(response.data.data)
        }else{
        //  swal(response.data.description)
        //  toast('Session Expired', {
        //   onClose: () => {
        //       window.location.href = '/'
        //   },
        //   autoClose: 5000
        // })
        }
       
        console.log(response.data.data)
        
      },
      (error) => {
          setLoading(false)
          // toast('Session Expired', {
          //     onClose: () => {
          //       window.location.href = '/'
          //     },
          //     autoClose: 5000
          //   })
      }
      )
  },[])

  return (
    <>
    <AddAdmin
        open={open}
        handleClose={handleClose}
    />
    <ChangeRole
       open={open1}
        handleClose={handleClose1}
        email={email}
    />
    <DeActivateModal                                                                                                                      
      open={open2}
      handleClose={handleClose2}
      id={id}
      status={status}
    />
    <div className=' mb-5 flex justify-between'>
        <ButtonPlus
            name='Add admin user'
            onClick={handleOpen}
        />
    </div>
      <Loading
        open={loading}
      />
    <Paper className=' mt-6' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>First name</StyledTableCell>
            <StyledTableCell align="left">Last name</StyledTableCell>
            <StyledTableCell align="left">Email address</StyledTableCell>
            <StyledTableCell align="right">Phone number</StyledTableCell>
            <StyledTableCell align="right">Role</StyledTableCell>
            <StyledTableCell align="right">Date created</StyledTableCell>
            <StyledTableCell align="right">Account status</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {row.firstName}
              </TableCell>
              <TableCell align="left">{row.lastName}</TableCell>
              <TableCell align="left">{row.emailAddress}</TableCell>
              <TableCell align="right">{row.phoneNumber}</TableCell>
              <TableCell align="right">{row.userRole}</TableCell>
              <TableCell align="right" className=' text-primary-color '>{row.dateCreated == null ? row.dateCreated :format(parseISO(row.dateCreated), 'MMM d yyyy')}</TableCell>
              <TableCell align="right" className=' text-primary-color '>{row.status === "active" ? <span className=' rounded-[32px] p-3 bg-green-700 text-white'>Active</span> : <span className=' rounded-[32px] p-3 bg-red-700 text-white'>Inactive</span>}</TableCell>
              <TableCell align="right" className=' text-primary-color '> <BorderColorIcon onClick={() => handleOpen1(row.emailAddress)}/> 
              <CheckCircleIcon className=' text-primary-color' onClick={() =>{
                    handleOpen2(row.id, 'acti')
              } } /> 
              <HighlightOffIcon className=' text-red-600' onClick={() =>{
                    handleOpen2(row.id, 'de')
              } } /></TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={user.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </>
  );
}