import React, { useState } from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PinUpdate from './Pin';
import PasswordUpdate from './ChangePassword';
function Security() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleOpen1 = () => setOpen1(true)
    const handleClose1 = () => setOpen1(false)
  return (
    <div>
    <PinUpdate
        open={open}
        handleClose={handleClose}
    />
    <PasswordUpdate
        open={open1}
        handleClose={handleClose1}
    />
    <div className='flex mb-4 gap-6 '>
        <div >
        <div className=' flex gap-8'>
        <h1 className=' text-[24px] font-bold mb-4 underline cursor-pointer' onClick={handleOpen}>Update PIN</h1> <BorderColorIcon onClick={handleOpen} className='cursor-pointer text-primary-color'/>
        </div>
           
            <p className=' text-[16px]'> Change or reset your Zouse PIN</p>
        </div>
       
    </div>
    <div className='flex mb-4 gap-6 '>
        <div >
        <div className=' flex gap-8'>
        <h1 className=' text-[24px] font-bold mb-4 underline cursor-pointer' onClick={handleOpen1}>Update Password</h1> <BorderColorIcon onClick={handleOpen1} className='cursor-pointer text-primary-color'/>
        </div>
           
            <p className=' text-[16px]'> Change or reset your Zouse Password</p>
        </div>
       
    </div>
    </div>
  )
}

export default Security