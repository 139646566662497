import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import Loading from '../SelectValue/Loading'
import postService from '../../Services/post-service'
import swal from 'sweetalert';
import Logo from '../../Assets/images/Zlogo.png'
import cookie from 'react-cookies'
import { Link } from 'react-router-dom'
// import Constant from '../../Constant'
function Loginform() {
  const [loading, setLoading] = useState(false)
    const initialValues = { 
        email: '',
        password: '',

      }
      const validationSchema = Yup.object({
        
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
      //  password: Yup.string()
      //  .required('Please Enter your password')
      //  .matches(
      //    Constant.passwordValidation,
      //    Constant.passwordValidationMessage
      //  ),
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {email, password} = values
        postService.userLogin(email, password).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              localStorage.setItem('userdetails', JSON.stringify(response.data.data))
              localStorage.setItem('otpStatus', response.data.data.using2fa)
              localStorage.setItem('userRole', response.data.data.userRole)
              cookie.save("ltoken", response.data.data.token, {
                path: "/",
                expires: new Date(Date.now() + 60 * 10 * 1000),
              });

              // localStorage.setItem('token',response.data.data.token)
              return   window.location.href = '/verification'
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  swal('Login Failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
        // navigate('/dashboard')
      }

  return (
    <div className=' bg-white shadow-lg p-16 rounded-[16px]'>
    <Loading
      open={loading}
    />
        <div className=' '>
        <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
            <p className=' mt-6 text-[18px] text-back-color2 mb-5 md:text-[40px] font-[500]'>Login to your account</p>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                type='email'
                label='Email address'
                name='email'
              />  
               
               <FormikControl
                control='password'
                label='Password'
                name='password'
              /> 
             
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Login'
              />
              </div>
              
              
        </Form>
        )}
            </Formik>
           <Link to='/forgetpass'><p className=' flex justify-end text-primary-color'> Forget password?</p></Link> 
            </div>
        </div>
    </div>
  )
}

export default Loginform