import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import getServices from '../../Services/get-service';
import Button from '../SelectValue/Button';
import DatePicker from 'react-datepicker';
import Loading from '../SelectValue/Loading';
import { format } from 'date-fns';
import UpdateProperty from './UpdateProperty';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
//   const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//     // hide last border
//     '&:last-child td, &:last-child th': {
//       border: 0,
//     },
//   }));
  



export default function PropertyTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen]= useState(false)
    const handleOpen = (con, des, title, loc,id) => {
      setOpen(true)
      setContact(con)
      setDes(des)
      setTitle(title)
      setLocality(loc)
      setId(id)
    }
    const handleClose = () => setOpen(false)
    const [propList, setPropList] = useState([])
    const [loading, setLoading] = useState(false)
    const [contact, setContact] = useState('')
  const [des, setDes] = useState('')
  const [title, setTitle] = useState('')
  const [locality, setLocality] = useState('')
  const [id, setId] = useState('')
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twoWeekAgo = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
  const [click, setClick] = useState(false)
  const [fromDate, setFromDate] = useState(oneWeekAgo);
  const [toDate, setToDate] = useState(currentDate);
const isDateValid = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero for comparison
  return date <= today;
};


useEffect(() =>{
  const newTodate= format(toDate, 'yyyy-MM-dd');
  const newFromdate=format(fromDate, 'yyyy-MM-dd'); 
  setLoading(true)
    getServices.getProjectListed(newFromdate, newTodate).then(
      (response) => {
        setLoading(false)
      if(response.data.status === "2000"){
        setPropList(response.data.propertyPlan.reverse())
        console.log(response.data.propertyPlan)
      }else{
     
      }
     
      console.log(response.data.data)
      
    },
    (error) => {
        setLoading(false)
        // toast('Session Expired', {
        //     onClose: () => {
        //       window.location.href = '/'
        //     },
        //     autoClose: 5000
        //   })
    }
    )
},[fromDate, toDate])

const approvedData = propList.filter(item => item.status !== 'inactive');
  return (
    <>
    <Loading open={loading}/>
    <UpdateProperty
    openn={open}
    handleCloses={handleClose}
    ti={title}
    de={des}
    con={contact}
    loc={locality}
    id={id}
    />
    <p className=' text-[24px] font-[400]'>Listed properties</p>
    <div className=' mb-5 grid grid-cols-2 md:grid-cols-8 gap-4'>
                <div className=' pt-2 col-span-3'>
                    <p className=' text-[20px] text-black font-normal'></p>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(oneWeekAgo)
                      setClick(false)
                      }}  
                    className={!click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 7 days</button>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(twoWeekAgo)
                      setClick(true)
                      }} 
                    className={click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 14 days</button>
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'> From:</p>
                  <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'> To:</p>
               <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={toDate}
                onChange={(date) => setToDate(date).toISOString()}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div>
                    <Button
                        name='Search'
                    />
                </div>
            </div>
    <Paper className=' mt-6' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Property name</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Property type</StyledTableCell>
            <StyledTableCell align="right">Partner name</StyledTableCell>
            <StyledTableCell align="right">Location</StyledTableCell>
            <StyledTableCell align="right">Date added</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {approvedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">{row.pricePerPlot}</TableCell>
              <TableCell align="right">{row.propertyType}</TableCell>
              <TableCell align="right">{row.createdBy}</TableCell>
              <TableCell align="right">{row.locality}, {row.street}</TableCell>
              <TableCell align="right" className=' text-primary-color '>{row.createdDate === null ? 'No date': row.createdDate.slice(0,10)}</TableCell>
              <TableCell align="right" className=' text-primary-color '><CheckCircleIcon className=' text-primary-color' /> <PendingOutlinedIcon 
              onClick={() =>{
                handleOpen('',row.description,row.title,row.locality,row.propertyId)
              }} 

              /></TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={approvedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div className=' my-3'>
          <p>{ approvedData.length === 0 && <p className=' text-red-600  text-center'>No property  found</p>}</p>
      </div>
    </Paper>
    </>
  );
}