import React from 'react'
import Youtube from '../../Assets/images/utube.png'
function Video() {
  return (
    <div className=' bg-[#E6E6E7] rounded-lg grid h-[30vh] place-items-center'>
        <img src={Youtube} alt='Utube'/>
    </div>
  )
}

export default Video