import React, {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../SelectValue/Button';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import postService from '../../Services/post-service';
import swal from 'sweetalert';
import Loading from '../SelectValue/Loading';
import getServices from '../../Services/get-service';
import Pin from '../SelectValue/Pins';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function ChangeRole({open, handleClose, email}) {
  const [loading, setLoading] = useState(false)
  const [role, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [open1, setOpen1]= useState(false)
    const handleClose1 = () => setOpen1(false)
    const [pin, setPin] = useState('')

     const onSubmit = (e)=> {
        e.preventDefault()
        setLoading(true)
       
        postService.changeRole(email,selectedRole,pin).then(
          (response) => {
              console.log(response.data)
             setLoading(false)
             if(response.data.status === '2000'){
            
             
                  swal("Admin role changed").then((result) => {
                    return   window.location.href = '/users'
                  })
              // localStorage.setItem('token',response.data.data.token)
             
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
             // setLoading(false)
                return  swal('Error')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
      }
    useEffect(() =>{
        setLoading(true)
          getServices.getRole().then(
            (response) => {
              setLoading(false)
            if(response.data.status === "2000"){
              setRoles(response.data.data.reverse())
              console.log(response.data.data)
            }else{
            //  swal(response.data.description)
            //  toast('Session Expired', {
            //   onClose: () => {
            //       window.location.href = '/'
            //   },
            //   autoClose: 5000
            // })
            }
           
            console.log(response.data.data)
            
          },
          (error) => {
              setLoading(false)
              // toast('Session Expired', {
              //     onClose: () => {
              //       window.location.href = '/'
              //     },
              //     autoClose: 5000
              //   })
          }
          )
      },[])

      const onSub = (e)=>{
            e.preventDefault()
            setOpen1(true)
      }
  return (
    <div>
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
     
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'>
            <Loading
            open={loading}
          />
           <Pin
        open={open1}
        handleClose={handleClose1}
        loading={loading}
        onSubmit={onSubmit}
        pin={pin}
        setPin={setPin}
      />
            <div className=' md:mt-8'>
                <div className=' flex justify-between my-5'>
                <div>
                    <p className=' my-5 text-center text-back-color2 font-[500] text-[20px]'>Update role</p>
                </div>
                    <CancelRoundedIcon className=' cursor-pointer' onClick={handleClose} fontSize='medium'/>
                </div>
               
                <div>
              
        <form onSubmit={onSub}>
            <div className=' grid mb-3 md:grid-cols-1 gap-5'>
            <label className=' block mb-3'> Select Admin role</label>
                    <select value={selectedRole} required className=' w-full border rounded-lg p-4 outline-0' onChange={(e) => setSelectedRole(e.target.value)}>
                        <option value=''>Select role</option>
                        {role.map((e, i) =>{
                            return <option value={e.inviteRole} key={i}>{e.inviteRole}</option>
                        })}
                    </select>
              <div className=' grid grid-cols-1 mb-5 mt-10'>
              <Button
                name='Update role'
              />
              </div>
           </div>
              
        </form>
       
                </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}