import React, { useEffect, useState } from 'react'
import SavingsTable from './SavingsTable'
import TopDCom from './TopDCom'
import getServices from '../../Services/get-service'
import ButtonPlus from '../SelectValue/ButtonPlus'
import SetRate from './SetRate'

function MainCon() {
    const [totalActiveSavingPlan, setTotalActiveSavingsPlan] = useState("0")
    const[ totalAmountSaved, setTotalAmountSaved] = useState("0")
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    // omni
    const [totalAmountWithdrawn, setTotalAmountWithdrawn] = useState("0")
    useEffect(() =>{
        getServices.getAllCount().then(
            (response) => {
               
              if(response.data.status === "2000"){
                setTotalAmountWithdrawn(response.data.data.totalwithdrawalAmount)
                setTotalActiveSavingsPlan(response.data.data.totalActiveSavingsPlan)
              }else{
              //  swal(response.data.description)
              //  toast('Session Expired', {
              //   onClose: () => {
              //       window.location.href = '/'
              //   },
              //   autoClose: 5000
              // })
              }
              console.log(response.data.data)
            },
            (error) => {
               
            }
        )
    }, [])
  return (
    <div>
    <SetRate
        open={open}
        handleClose={handleClose}
    />
    <ButtonPlus
        name={"Set savings rate"}
        onClick={handleOpen}
    />
        <div className=' grid md:grid-cols-3 gap-4'>
            <TopDCom
                name='Total Active savings plans'
                count={totalActiveSavingPlan === 'nu' ? "0" : totalActiveSavingPlan}
            />
            <TopDCom
                name='Total  Amount saved'
                count={totalAmountSaved === 'nu' ? "0" : totalAmountSaved}
            />
            <TopDCom
                name='Total amount withdrawn'
                count={totalAmountWithdrawn === 'nu' ? "0" : totalAmountWithdrawn}
            />
        </div>
        <div>
            <SavingsTable/>
        </div>
    </div>
  )
}

export default MainCon