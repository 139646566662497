import React from 'react'
import Overview from '../../Components/Dashboard/Overview'

function MainDashboard() {
  return (
    <div>
      <Overview/>
    </div>
  )
}

export default MainDashboard