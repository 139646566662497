import React, {useEffect, useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import Loading from '../SelectValue/Loading'
import postService from '../../Services/post-service'
import swal from 'sweetalert';
import Logo from '../../Assets/images/Zlogo.png'
import cookie from 'react-cookies'
import { Link } from 'react-router-dom'
import getServices from '../../Services/get-service'
// import Constant from '../../Constant'
function OtpForm() {
  const [loading, setLoading] = useState(false)
  const details = localStorage.getItem('userdetails')
  const status = localStorage.getItem('otpStatus')
  const [image, setImages] = useState('')
  const user =  JSON.parse(details)
  useEffect(() =>{
    setLoading(true)
      getServices.get2faSetup().then(
        (response) => {
          setLoading(false)
        if(response.data.status === "2000"){
            setImages(response.data.data.qrCode)
        }else{
        //  swal(response.data.description)
        //  toast('Session Expired', {
        //   onClose: () => {
        //       window.location.href = '/'
        //   },
        //   autoClose: 5000
        // })
        }
       
        console.log(response.data.data)
        
      },
      (error) => {
          setLoading(false)
          // toast('Session Expired', {
          //     onClose: () => {
          //       window.location.href = '/'
          //     },
          //     autoClose: 5000
          //   })
      }
      )
  },[])
    const initialValues = { 
        otp: '',

      }
      const validationSchema = Yup.object({
        otp: Yup.string()
        .matches(/^\d{6}$/, 'PIN must be a 6-digit number')
        .required('OTP is required'),   
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
      //  password: Yup.string()
      //  .required('Please Enter your password')
      //  .matches(
      //    Constant.passwordValidation,
      //    Constant.passwordValidationMessage
      //  ),
            
      })

      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {otp} = values
        postService.validateOtp(otp, user.emailAddress).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
            //   localStorage.setItem('userdetails', JSON.stringify(response.data.data))
              cookie.save("token", response.data.data, {
                path: "/",
                expires: new Date(Date.now() + 60 * 10 * 1000),
              });

              // localStorage.setItem('token',response.data.data.token)
              return   window.location.href = '/dashboard'
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  swal('Login Failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
        // navigate('/dashboard')
      }

      const restPass = (e) =>{
                e.preventDefault()
                setLoading(true)
                postService.forgetPin().then(
                    (response) => {
                        setLoading(false)
                        console.log(response.data)
                        setLoading(false)
                       if(response.data.status === '2000'){
                        localStorage.setItem('reques',response.data.data.requestId)
                        swal('Otp sent to mail successfully!').then(() =>{
                            return   window.location.href = '/reset'
                        })
                         
                       
                       }else{
                        swal(response.data.description)
                       
                       }
                       
                      },
                      (error) => {
                        setLoading(false)
                          return  swal('Error occured')
                          .then((value) => {
                            window.location.reload()
                          });
                        }
                  )
      }

  return (
    <div className=' bg-white shadow-lg p-16 rounded-[16px]'>
    <Loading
      open={loading}
    />
        <div className=' '>
        <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
            <p className=' mt-6 text-[18px] text-back-color2 mb-5 md:text-[25px] font-[500]'>Zouse - Two Factor Authentication</p>
            <div>
            <a className=' text-blue-500' href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
                          Click here to download Google Authenticator
            </a>
            </div>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            <div>
            {status !== 'true' && 
             <img
            className=' mx-auto'
               src={
                 "data:image/png;base64, " +
                   image
                   }
                   alt="QR code"
                    />
                    }
           
            </div>
               <FormikControl
                control='input'
                type='text'
                label='Enter Authenticator Code'
                maxLength="6"
                name='otp'
              />  
             
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Verify'
              />
              </div>
              
              
        </Form>
        )}
            </Formik>
           <p onClick={restPass} className=' cursor-pointer flex justify-end text-primary-color'> Reset Two Authenticator?</p>
            </div>
        </div>
    </div>
  )
}

export default OtpForm