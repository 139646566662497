import axios from "axios";
import authHeader from "./AuthHeader";
import cookie from 'react-cookies'

const tokens = cookie.load('ltoken')
const setupTransactionPin = ( pim) =>{
    const tokens = cookie.load('jwttoken')
    const value = { "transactionPin":pim}

    return axios.post('/api/set-pin', value,{
        headers: {
          'Authorization': tokens 
        }
      } )
}


const userLogin = (email, password) =>{
    const value = {"emailAddress": email, "password": password}
    return axios.post(`/api/user-login-admin`, value)
}
const uploadProperty = (bathrooms,bedrooms,coveredArea,description,locality,price,propertyDuration,propertyId,propertyType, street, title, toilets, totalArea, transactionPin, videoLink) =>{
    const value = {"bathrooms": bathrooms, "bedrooms": bedrooms, "coveredArea": coveredArea, "description":description, "locality": locality,"price":price, "propertyDuration":propertyDuration,"propertyId": propertyId,"propertyType":propertyType,"street":street, "title":title, "toilets": toilets, "totalArea":totalArea, "transactionPin":transactionPin,"videoLink":videoLink}
    return axios.post(`/api/upload-properties`, value, { headers: authHeader()})
}
const uploadProject = (contact,priceperunit,projectLocation,projectDescription,projectDuration,projectLink,projectName, projectType, rate, totalUnits, transactionPin, file,earlyExit ) =>{
    const formData = new FormData();
    formData.append('contact', contact);
    formData.append('earlyExit', earlyExit);
    formData.append('pricePerUnit', priceperunit);
    formData.append('projectLocation', projectLocation);
    formData.append('projectDescription', projectDescription);
    formData.append('projectDuration', projectDuration);
    formData.append('projectLink', projectLink);
    formData.append('projectName', projectName);
    formData.append('projectType', projectType);
    formData.append('rate', rate);
    formData.append('totalUnits', totalUnits);
    formData.append('transactionPin', transactionPin);
    file.forEach((image) => {
        formData.append('file', image);
      });
    return axios.post(`/api/upload-project`, formData, { headers: authHeader()})
}

const uploadProjectt = (value) =>{

    return axios.post('/api/upload-project', value, { headers: authHeader()} )
}
const uploadPropertyListing = (value) =>{
    return axios.post('/api/upload-properties', value, { headers: authHeader()} )
}
const uploadlandListing = (value) =>{
    return axios.post('/api/land-listing', value, { headers: authHeader()} )
}

const userInvite = (email, lastName, firstName, pNumber, userRole) =>{
    const value = {"emailAddress": email, "firstName": firstName, "lastName": lastName, "phoneNumber": pNumber, "userRole":userRole }
    return axios.post(`/api/invite-user`, value, { headers: authHeader()})
}
const userCompleteSignup = (phoneNumber, password, transactionPin,uuid) =>{
    const value = {"phoneNumber": phoneNumber, "password": password, "uuid":uuid, "transactionPin":transactionPin}
    return axios.post(`/api/complete-signup-admin`, value)
}
const deactivateUser = (id, pin) =>{
    const value = {}
    return axios.post(`/api/deActivate-user?id=${id}&transactionPin=${pin}`, value, { headers: authHeader()})
}

const activateUser = (id, pin) =>{
    const value = {}
    return axios.post(`/api/activate-user?id=${id}&transactionPin=${pin}`, value, { headers: authHeader()})
}
const changePin = (oldPin, newPin) =>{
    const value = {"newPin": newPin, "oldPin": oldPin }
    return axios.post(`/api/change-pin-admin`, value, { headers: authHeader()})
}
const changePassword = (oldPassword, newPassword) =>{
    const value = {"newPassword": newPassword, "oldPassword": oldPassword }
    return axios.post(`/api/change-password-admin`, value, { headers: authHeader()})
}
const forgetPassMail = (email) =>{
    const value = {}
    return axios.post(`/api/forgot-password-admin?emailAddress=${email}`, value, { headers: authHeader()})
}
const conForgetPassMail = (otp, pass, requestId) =>{
    const value = {"requestId":requestId,"otp":otp,"password":pass}
    return axios.post(`/api/reset-password-admin`, value, { headers: authHeader()})
}
const addNotification = (header,  content) =>{
    const value = {"content":content,"header":header,}
    return axios.post(`/api/notifications`, value, { headers: authHeader()})
}

const validateOtp = (totp,  username) =>{
    const value = {"username":username,"totp":totp,}
    return axios.post(`/api/validateTotp`, value, { headers: {  
        
        Authorization: tokens 
   }})
}

const resetOtpp = (otp,  requestId, pin) =>{
    const value = {"requestId":requestId,"otp":otp,"pin":pin}
    return axios.post(`/api/reset-pin-admin`, value, { headers: {  
        
        Authorization: tokens 
   }})
}
const reset2fa = (otp,  requestId, ) =>{
    const value = {"requestId":requestId,"otp":otp,}
    return axios.post(`/api/reset-2fa-admin`, value, { headers: {  
        
        Authorization: tokens 
   }})
}
const forgetPin = () =>{
    const value = {}
    return axios.post(`/api/forgot-pin-admin`, value, { headers: {  
        
        Authorization: tokens 
   }})
}
const changeRole = (emailAddress,  newRole, transactionPin) =>{
    const value = {"newRole":newRole,"emailAddress":emailAddress,"transactionPin":transactionPin}
    return axios.post(`/api/change-role`, value, { headers: authHeader()})
}
const deactivateAdminUser = (id, transactionPin) =>{
    const value = {}
    return axios.post(`/api/de-activate-user-admin?id=${id}&transactionPin=${transactionPin}`, value, { headers: authHeader()})
}
const activateAdminUser = (id, transactionPin) =>{
    const value = {}
    return axios.post(`/api/activate-user-admin?id=${id}&transactionPin=${transactionPin}`, value, { headers: authHeader()})
}
const addRate = (duration, rate) =>{
    const value = {"duration": duration, "rate":rate}
    return axios.post(`/api/set-duration`, value, { headers: authHeader()})
}
const updateProperty = (contact, description, locality,propertyId,title,pin) =>{
    const value = {"contact": contact, "description":description,"transactionPin":pin, "locality":locality, 'propertyId':propertyId, "title":title}
    return axios.post(`/api/edit-properties`, value, { headers: authHeader()})
}
const updateProject = (contact, projectDescription, projectLocation,propertyId,title,pin) =>{
    const value = {"contact": contact, "projectDescription":projectDescription,"transactionPin":pin, "projectLocation":projectLocation, 'projectId':propertyId, "title":title}
    return axios.post(`/api/edit-project`, value, { headers: authHeader()})
}
const postService = {
    addRate,
    reset2fa,
    updateProject,
    updateProperty,
    changeRole,
    deactivateAdminUser,
    activateAdminUser,
    setupTransactionPin,
    userLogin,
    uploadProject,
    uploadProperty,
    uploadProjectt,
    uploadPropertyListing,
    uploadlandListing,
    userInvite,
    userCompleteSignup,
    activateUser,
    deactivateUser,
    changePin,
    changePassword,
    forgetPassMail,
    conForgetPassMail,
    addNotification,
    validateOtp,
    resetOtpp,
    forgetPin
}

export default postService