import React from 'react'
import Section from '../../Components/Settings/Section'

function Settings() {
  return (
    <div>
      <Section/>
    </div>
  )
}

export default Settings