import React from 'react'
import Back from '../Back'
import Full from '../../Assets/images/fullp.png'
import Empty from '../../Assets/images/epro.png'
import Download from '../../Assets/images/downl.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '../SelectValue/Button'
function UploadPictures() {
  return (
    <div className=' px-6 py-3 '>
    <div className=' flex  gap-5'>
        <Back/> <p>Upload pictures</p>
    </div>
    <div className=' flex mt-7 justify-between gap-4'>
                <div>
                    <img src={Full} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Project details <CheckCircleIcon className=' text-primary-color'/></p>
                </div>
                <div>
                    <img src={Full} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Pictures <CheckCircleIcon className=' text-primary-color'/></p>
                </div>
                <div>
                    <img src={Empty} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Finish <CheckCircleOutlineIcon className=' text-[#44474E]'/></p>
                </div>
    </div>
    <div>
        <div className=' border-dashed mt-6 mx-5 border-8  border-gray-200 p-10'>
           <img src={Download} alt='download' className=' mx-auto'/>
           <p className=' text-center font-[500] text-[14px] text-back-color2 py-2'>Drag and drop pictures here</p>
           <p className=' text-center font-[400] text-[12px] text-primary-color6'>Or</p>
           <div className=' flex justify-center pl-16 my-5'>
           <div>
           <form>
                <input type="file" id="myFile" name="filename"/>
                </form>
           </div>
                
           </div>
           <p className=' text-center font-[400] text-[12px] text-primary-color6'>Maximum of 25 pictures, Minimum of 12 pictures</p>
        </div>
    </div>
    <div className=' mt-5 rounded-lg bg-[#AAE9CC] p-5 flex gap-4'>
        <div>
            <p>Picture upload successful</p>
        </div>
        <div>
        <CheckCircleIcon className=' text-primary-color'/> 
        </div>
    </div>
    <div className=' mt-7 flex gap-3 justify-end'>
        <div>
            <p className='p-3 text-back-color2 bg-[#FAFBFC] rounded-lg font-[700]'>Edit property details</p>
        </div>
        <Button
            name='Save and finish'
        />
    </div>
    </div>
  )
}

export default UploadPictures