import React, { useEffect, useState } from 'react'
import Button from '../SelectValue/Button'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import TopDCom from './TopDCom';
import Chart from './Chart';
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import Loading from '../SelectValue/Loading';
import DatePicker from 'react-datepicker';
function Overview() {
    const currentDate = new Date();
    const [totalInvestAv, setTotalInvestAv] = useState('0')
    const [totalUnitAva, setTotalUnitAva] =  useState('0')
    const [tAmountSave, setTAmountSave] =  useState('0')
    const [tAmountWithdraw, setTAmountWithdraw] =  useState('0')
    const [proSold, setPropSold] =  useState('0')
    const [propListed, setProListed] =  useState('0')
    const [activeProject, setActiveProject] = useState('0')
    const [completedProject, setCompletedProject] = useState('0')
    const [investors, setInvestors] =  useState('0')
    const [patners, setPatners] =  useState('0')
  const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twoWeekAgo = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
  const [click, setClick] = useState(false)
  const [fromDate, setFromDate] = useState(oneWeekAgo);
  const [toDate, setToDate] = useState(currentDate);
  const [loading, setLoading] = useState(false)
const isDateValid = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero for comparison
  return date <= today;
};
  useEffect(() =>{
    const newTodate= toDate.toISOString()
    const newFromdate= fromDate.toISOString()
    
    setLoading(true)
      getServices.getDashboardCount(newFromdate, newTodate).then(
        (response) => {
          setLoading(false)
        if(response.data.status === "2000"){
         setTotalInvestAv(response.data.data.totalSumActiveInvestment)
         setTotalUnitAva(response.data.data.totalSumCompletedInvestment)
         setTAmountSave(response.data.data.totalSumCompletedInvestment)
         setTAmountWithdraw(response.data.data.totalwithdrawalAmount)
         setProListed(response.data.data.totalSumListedProperty)
         setPropSold(response.data.data.totalSumpropertySold)
         setActiveProject(response.data.data.totalSumActiveProject)
         setCompletedProject(response.data.data.totalPendingProject)
         //setInvestors()
        }else{
         swal(response.data.description)
        //  toast('Session Expired', {
        //   onClose: () => {
        //       window.location.href = '/'
        //   },
        //   autoClose: 5000
        // })
        }
       
        console.log(response.data.data)
        
      },
      (error) => {
          setLoading(false)
          // toast('Session Expired', {
          //     onClose: () => {
          //       window.location.href = '/'
          //     },
          //     autoClose: 5000
          //   })
      }
      )
  },[fromDate, toDate])
  return (
    <div>
        <div>

        <Loading
      open={loading}
    />
    
      <div className=' mb-5 grid grid-cols-2 md:grid-cols-8 gap-4'>
                <div className=' pt-2 col-span-3'>
                    <p className=' text-[20px] text-black font-normal'>Overview</p>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(oneWeekAgo)
                      setClick(false)
                      }}  
                    className={!click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 7 days</button>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(twoWeekAgo)
                      setClick(true)
                      }} 
                    className={click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 14 days</button>
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'> From:</p>
                  <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'>To:</p>
               <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={toDate}
                onChange={(date) => setToDate(date).toISOString()}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div>
                    <Button
                        name='Search'
                    />
                </div>
            </div>
            <div className=' grid my-8 md:grid-cols-2 gap-4'>
                <div className=' rounded-lg bg-[#F8F8F8] p-3'>
                <div className=' flex justify-between'>
                <p className=' font-[400] text-[14px]'>Investment</p>
                <PendingOutlinedIcon/>
               
                </div>
                <div className=' grid gap-4 xl:grid-cols-2'>
                    <TopDCom
                        name='Total investments available'
                        count={totalInvestAv}
                        bool={true}
                    />
                    <TopDCom
                        name='Total units available'
                        count={totalUnitAva}
                        bool={true}
                    />
                </div>
                </div>
                <div className=' rounded-lg bg-[#F8F8F8] p-3'>
                <div className=' flex justify-between'>
                <p className=' font-[400] text-[14px]'>Savings</p>
                <PendingOutlinedIcon/>
               
                </div>
                <div className=' grid gap-4 xl:grid-cols-2'>
                    <TopDCom
                        name='Total  Amount saved'
                        count={tAmountSave}
                        bool={true}
                    />
                    <TopDCom
                        name='Total amount withdrawn'
                        count={tAmountWithdraw}
                        bool={true}
                    />
                </div>
                </div>
            </div>
            <Chart/>
            <div className='grid my-8 md:grid-cols-3 gap-4'>
            <div className=' rounded-lg border border-gray-200 bg-white p-3'>
                <div className=' flex justify-between'>
                <p className=' font-[400] text-[14px]'>Properties</p>
                <PendingOutlinedIcon/>
               
                </div>
                <div className=' grid gap-4 xl:grid-cols-2'>
                    <TopDCom
                        name='Properties listed'
                        count={propListed}
                        
                    />
                    <TopDCom
                        name='Properties sold'
                        count={proSold}
                       
                    />
                </div>
                </div>
                <div className=' rounded-lg border border-gray-200 bg-white p-3'>
                <div className=' flex justify-between'>
                <p className=' font-[400] text-[14px]'>Projects</p>
                <PendingOutlinedIcon/>
               
                </div>
                <div className=' grid gap-4 xl:grid-cols-2'>
                    <TopDCom
                        name='Active projects'
                        count={activeProject}
                        
                    />
                    <TopDCom
                        name='Completed projects'
                        count={completedProject}
                       
                    />
                </div>
                </div>
                <div className=' rounded-lg border border-gray-200 bg-white p-3'>
                <div className=' flex justify-between'>
                <p className=' font-[400] text-[14px]'>Customers</p>
                <PendingOutlinedIcon/>
               
                </div>
                <div className=' grid gap-4 xl:grid-cols-2'>
                    <TopDCom
                        name='Investors'
                        count={investors}
                        
                    />
                    <TopDCom
                        name='Partners'
                        count={patners}
                       
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Overview