import React, { useEffect, useState } from 'react'
import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Inputtt from '../SelectValue/Inputtt';
import { Divider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import postService from '../../Services/post-service';
import Pin from '../SelectValue/Pins';
import { Drawer } from '@mui/material';
const drawerWidth = 650;
function UpdateProject({openn, handleCloses, window, ti, de, con,loc, id}) {
   
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState('')
  const [des, setDes] = useState('')
  const [title, setTitle] = useState('')
  const [locality, setLocality] = useState('')
  const [transactionPin, setTransactionPin] = useState('')
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

    useEffect(() =>{
         setDes(de)
         setTitle(ti)
         setLocality(loc)
         setContact(con)
    },[de, ti, con, loc])

  const handleSubmit = (e) =>{
    e.preventDefault()
    setLoading(true)
     
      postService.updateProject(contact,des,locality,id,title,transactionPin).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            handleClose()
            toast.success("Project update  successfully!!", (()=>{
                window.location.reload();
              
            }));
           
            setTimeout(() => {
                window.location.reload();
            }, 5000);
          
           console.log(response.data.description)
           }else{
            toast.error(response.data.description);
           
           }
           
          },
          (error) => {
            setLoading(false)
            toast.error(error.message);
            setTimeout(() => {
              window.location.reload()
            }, 5000);
            }
      )
}
const handleSubmitt = (e) =>{
  e.preventDefault()

    setOpen(true)
 
}

  return (
    <div>
     <Drawer
          anchor='right'
          variant="temporary"
          open={openn}
          onClose={handleCloses}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
     <Pin
  open={open}
  handleClose={handleClose}
  loading={loading}
  onSubmit={handleSubmit}
  pin={transactionPin}
  setPin={setTransactionPin}
    />
    <ToastContainer/>
        <div className=' px-5 pt-3'>
        <div className=' flex justify-end'>
        <CancelIcon onClick={handleCloses} className=' text-back-color2 text-[38px]' fontSize="large"/>
        </div>
            <h1 className=' font-extrabold  pb-5'>Update Project Details</h1>
            
            <div className='  mt-5'>
            <form onSubmit={handleSubmitt}>
                <div className='grid  my-4'>
                <Inputtt
                        label='Title'
                        value={title}
                        onChange={setTitle}
                    />
                </div>
                <p className=' font-[500] mb-4 text-[#44474E] text-[14px]'> <span className=' font-[800] text-back-color2 text-[14px]'>Note:</span>  Do NOT add location information to the listing title</p>
                <Divider/>
                
                <div className=' grid sm:grid-cols-1 gap-3  my-4 '>
                
                    {/* <Inputtt
                        label='Contact'
                        value={contact}
                        onChange={setContact}
                    /> */}
                    <Inputtt
                        label='Location'
                        value={locality}
                        onChange={setLocality}
                    />
                </div>
                
           
                <div className=' grid mb-16  my-4 '>
                    <label className=' mb-3 block'>Description</label>
                    <textarea
                    minLength={15}
                        className=' border p-4 outline-0 rounded-lg '
                        value={des}
                        required
                        onChange={(e) => setDes(e.target.value)}
                    />
                </div>
                
                 <div className=' flex mt-8 justify-end'>
                    <Button
                        name='Update'
                    />
             </div>
            </form>
            </div>
            
        </div>
        </Drawer>
    </div>
    
  )
}

export default UpdateProject