import React from 'react'
import Button from '../SelectValue/Button'

function ProjectDescription() {
  return (
    <div>
         <div>
        <div className=' p-3 mb-7 border border-primary-color6 rounded-lg'>
                <p className=' text-[16px] mb-5 font-[500] text-back-color2'>Land description</p>
                <p className=' text-[16px] mt-3 font-[400] text-[#808285]'>Body 1 Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. 
                <br></br>
                <br></br>
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, </p>
        </div>
        <div className=' p-3 mb-7 border border-primary-color6 rounded-lg'>
                <p className=' text-[16px] mb-5 font-[500] text-back-color2'>Land details</p>
                <div className=' flex  mb-5 gap-5'>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Covered area: <span className=' text-back-color2'>800 sqm</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Market status: <span className=' text-back-color2'>Available</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Total area:  <span className=' text-back-color2'> 800 sqm</span></p>      
                </div>
                <div className=' flex  mb-5 gap-5'>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Property Ref:  <span className=' text-back-color2'>1628292</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Added on: <span className=' text-back-color2'> hfhf</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Type:   <span className=' text-back-color2 capitalize'>housing</span></p>      
                </div>
                </div>
            <Button
                name='Approve project'
            />
    </div>
    </div>
  )
}

export default ProjectDescription