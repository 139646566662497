import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import getServices from '../../Services/get-service';
import Loading from '../SelectValue/Loading';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
//   const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//     // hide last border
//     '&:last-child td, &:last-child th': {
//       border: 0,
//     },
//   }));
  



export default function NotificationTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen]= useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [propList, setPropList] = useState([])
    const [loading, setLoading] = useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  


useEffect(() =>{
  
  setLoading(true)
    getServices.getNotification().then(
      (response) => {
        setLoading(false)
      if(response.data.status === "2000"){
        setPropList(response.data.data.reverse())
        console.log(response.data.data)
      }else{
      //  swal(response.data.description)
      //  toast('Session Expired', {
      //   onClose: () => {
      //       window.location.href = '/'
      //   },
      //   autoClose: 5000
      // })
      }
     
      console.log(response.data.data)
      
    },
    (error) => {
        setLoading(false)
        // toast('Session Expired', {
        //     onClose: () => {
        //       window.location.href = '/'
        //     },
        //     autoClose: 5000
        //   })
    }
    )
},[])


  return (
    <>
    <Loading open={loading}/>
    <p className=' text-[24px] font-[400]'>Notification Table</p>
   
    <Paper className=' mt-6' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell align="center">content</StyledTableCell>
            <StyledTableCell align="right">Date added</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.header}
              </TableCell>
              <TableCell align="center">{row.content}</TableCell>
              <TableCell align="right" className=' text-primary-color '>{row.createdDate === null ? 'No date': row.createdDate.slice(0,10)}</TableCell>
              <TableCell align="right" className=' text-primary-color '><CheckCircleIcon className=' text-primary-color' onClick={handleOpen}/> <PendingOutlinedIcon /></TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={propList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div className=' my-3'>
          <p>{ propList.length === 0 && <p className=' text-red-600  text-center'>No Notification found  found</p>}</p>
      </div>
    </Paper>
    </>
  );
}