import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '../SelectValue/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import { format, parseISO } from 'date-fns';
import Loading from '../SelectValue/Loading';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  







export default function TransactionTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =useState(10);
  const [investments, setInvestments] = useState([])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
const [loading, setLoading] = useState(false)
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twoWeekAgo = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
  const [click, setClick] = useState(false)
  const [fromDate, setFromDate] = useState(oneWeekAgo);
  const [toDate, setToDate] = useState(currentDate);
const isDateValid = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero for comparison
  return date <= today;
};
  useEffect(() =>{
    const newTodate= toDate.toISOString()
    const newFromdate= fromDate.toISOString()
    setLoading(true)
      getServices.getCustomInvestmentList(newFromdate, newTodate).then(
        (response) => {
          setLoading(false)
        if(response.data.status === "2000"){
          setInvestments(response.data.investmentPlans)
          console.log(response.data.investmentPlans)
        }else{
         swal(response.data.description)
        //  toast('Session Expired', {
        //   onClose: () => {
        //       window.location.href = '/'
        //   },
        //   autoClose: 5000
        // })
        }
       
        console.log(response.data.data)
        
      },
      (error) => {
          setLoading(false)
          // toast('Session Expired', {
          //     onClose: () => {
          //       window.location.href = '/'
          //     },
          //     autoClose: 5000
          //   })
      }
      )
  },[fromDate, toDate])
  
  return (
    <>
    <Loading
      open={loading}
    />
    
      <div className=' mb-5 grid grid-cols-2 md:grid-cols-8 gap-4'>
                <div className=' pt-2 col-span-3'>
                    <p className=' text-[20px] text-black font-normal'>Overview</p>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(oneWeekAgo)
                      setClick(false)
                      }}  
                    className={!click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 7 days</button>
                </div>
                <div className=' pt-2'>
                    <button onClick={() => {
                      setFromDate(twoWeekAgo)
                      setClick(true)
                      }} 
                    className={click ? ' font-normal text-[14px] inline-block text-back-color2 border-b-2 border-primary-color pb-2 text-center':'font-normal text-[14px] inline-block text-back-color2  pb-2 text-center'}>Last 14 days</button>
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'> From:</p>
                  <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div className=' pt-2 flex gap-2'>
                <p className=' mb-2 pt-1'> To:</p>
               <DatePicker
                    className=' w-full p-1 bg-white border-b border-gray-300 outline-0 rounded-lg'
                selected={toDate}
                onChange={(date) => setToDate(date).toISOString()}
                maxDate={new Date()} // Set the maximum selectable date to the currently selected date
                filterDate={isDateValid} // Apply custom date filtering logic
                popperPlacement="top-end"
                />
                </div>
                <div>
                    <Button
                        name='Search'
                    />
                </div>
            </div>
            <Paper className=' mt-16' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Investment name</StyledTableCell>
            <StyledTableCell align="start">Investment type</StyledTableCell>
            <StyledTableCell align="start">Investment cost</StyledTableCell>
            <StyledTableCell align="start">Unit</StyledTableCell>
            <StyledTableCell align="start">Project type</StyledTableCell>
            <StyledTableCell align="start">Payment Method</StyledTableCell>
            <StyledTableCell align="start">Email</StyledTableCell>
            <StyledTableCell align="start">Duration</StyledTableCell>
            <StyledTableCell align="start">Start date</StyledTableCell>
            <StyledTableCell align="start">End date</StyledTableCell>
            <StyledTableCell align="start">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {investments
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
            <TableRow
              key={row.projectId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.projectName}
              </TableCell>
              <TableCell align="start">{row.projectType}</TableCell>
              <TableCell align="start">{row.totalAmount}</TableCell>
              <TableCell align="start">{row.units}</TableCell>
              <TableCell align="start">{row.projectType}</TableCell>
              <TableCell align="start">{row.paymentMethod === 'string' ? 'Wallet':row.paymentMethod }</TableCell>
              <TableCell align="start" >{row.emailAddress}</TableCell>
              <TableCell align="start" >{row.projectDuration}days</TableCell>
              <TableCell align="start" >{format(parseISO(row.startDate), 'MMM d yyyy')}</TableCell>
              <TableCell align="start" >{format(parseISO(row.endDate), 'MMM d yyyy')}</TableCell>
              <TableCell align="start" className=' text-primary-color '>{row.status}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
        {investments.length === 0 && <p className='mt-3 text-center text-red-700'>No Investment found</p>}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={investments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </>
    
  );
}