import React, { useState } from 'react'
import Button from '../SelectValue/Button';
import { Link, useNavigate} from 'react-router-dom';
import Inputtt from '../SelectValue/Inputtt';
import { Divider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import postService from '../../Services/post-service';
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield';
import ImageUploader from '../Dropdown';
import Pin from '../SelectValue/Pins';

function AddProperty() {
   
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const[name, setName] = useState('')
  const [bathrooms, setBathrooms] = useState('')
  const [bedrooms, setBedrooms] = useState('')
  const [coveredArea, setCoveredArea] = useState('')
  const [kitchen, setKitchen] = useState('')
  const [locality , setLocality] = useState('')
  const [des, setDes] = useState('')
  const [sittinRoom, setSittinRoom] = useState('')
  const [street, setStreet] = useState('')
  const [toilets, setToilets] = useState('');
  const [totalPrice, setTotalPrice] = useState('')
  const [videoLink, setVideoLink] = useState('')
  const [transactionPin, setTransactionPin] = useState('')
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const navigate = useNavigate()
  const handleSubmit = (e) =>{
    e.preventDefault()
    setLoading(true)
    const formData = new FormData();
    images.forEach((image) => {
        formData.append('file', image);
      });
      formData.append('bathrooms', bathrooms);
      formData.append('bedrooms', bedrooms);
      formData.append('bedrooms',bedrooms);
      formData.append('coveredArea',coveredArea);
      formData.append('description', des);
      formData.append('kitchen', kitchen);
      formData.append('locality', locality);
      formData.append('sittinRoom', sittinRoom);
      formData.append('title', name);
      formData.append('street', street);
      formData.append('toilets', toilets);
      formData.append('totalPrice', totalPrice);
      formData.append('videoLink', videoLink);
      formData.append('transactionPin', transactionPin);
      postService.uploadPropertyListing(formData).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            toast.success("property listed  successfully!!");
            setTimeout(() => {
             navigate('/properties')
            }, 5000);
          
           console.log(response.data.description)
           }else{
            toast.error(response.data.description);
           
           }
           
          },
          (error) => {
            setLoading(false)
            toast.error(error.message);
            setTimeout(() => {
              window.location.reload()
            }, 5000);
            }
      )
}
const handleSubmitt = (e) =>{
  e.preventDefault()
  if(images.length > 0){
    setOpen(true)
  }else{
    toast.error("Select an image")
  }
}

  return (
    <div>
     <Pin
  open={open}
  handleClose={handleClose}
  loading={loading}
  onSubmit={handleSubmit}
  pin={transactionPin}
  setPin={setTransactionPin}
    />
    <ToastContainer/>
        <div className=' px-5 pt-3'>
            <p className=' pb-5'>Property Listing</p>
            {/* <div className=' flex justify-between gap-4'>
                <div>
                    <img src={Full} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Project details <CheckCircleIcon className=' text-primary-color'/></p>
                </div>
                <div>
                    <img src={Empty} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Pictures <CheckCircleOutlineIcon className=' text-[#44474E]'/></p>
                </div>
                <div>
                    <img src={Empty} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Finish <CheckCircleOutlineIcon className=' text-[#44474E]'/></p>
                </div>
            </div> */}
            <div className='  mt-5'>
            <form onSubmit={handleSubmitt}>
                <div className='grid  my-4'>
                <Inputtt
                        label='Title'
                        value={name}
                        onChange={setName}
                    />
                </div>
                <p className=' font-[500] mb-4 text-[#44474E] text-[14px]'> <span className=' font-[800] text-back-color2 text-[14px]'>Note:</span>  Do NOT add location information to the listing title</p>
                <Divider/>
                
                <div className=' grid sm:grid-cols-3 gap-3  my-4 '>
                
                    <NonnegativeInputField
                        label='Number of Sitting Room'
                        value={sittinRoom}
                        onChange={setSittinRoom}
                    />
                    <NonnegativeInputField
                        label='Number of Bathroom'
                        value={bathrooms}
                        onChange={setBathrooms}
                    />
                    <NonnegativeInputField
                        label='Number of Bedroom'
                        value={bedrooms}
                        onChange={setBedrooms}
                    />
                </div>
                <div className=' grid sm:grid-cols-3 gap-3  my-4 '>
                
                <NonnegativeInputField
                    label='Number of Kitchen'
                    value={kitchen}
                    onChange={setKitchen}
                />
                <NonnegativeInputField
                    label='Number of Toilet'
                    value={toilets}
                    onChange={setToilets}
                />
                   <Inputtt
                    label='Totalarea covered'
                    value={coveredArea}
                    onChange={setCoveredArea}
                />
            </div>
            <div className=' grid sm:grid-cols-2 gap-3  my-4 '>
                
             
                <Inputtt
                    label='Locality'
                    value={locality}
                    onChange={setLocality}
                />
                <Inputtt
                    label='Street'
                    value={street}
                    onChange={setStreet}
                />
            </div>
               
            
                <div className=' grid  my-4 '>
                        <NonnegativeInputField
                            label='Price of property'
                            valuee={totalPrice}
                            onChange={setTotalPrice}
                        />
                </div>
                <div className=' grid  my-4 '>
                       
                          <div className=' w-full'>
                        <label className=' block mb-2'>Property youtube video link</label> 
                            <input 
                                className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg'
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                            />
                        </div>
                </div>
              
                <div className=' grid mb-16  my-4 '>
                    <label className=' mb-3 block'>Description</label>
                    <textarea
                    minLength={7}
                        className=' border p-4 outline-0 rounded-lg '
                        value={des}
                        required
                        onChange={(e) => setDes(e.target.value)}
                    />
                </div>
                <ImageUploader
                    images={images}
                    setImages={setImages}
                />
                 <div className=' flex mt-8 justify-end'>
                    <Button
                        name='Save continue'
                    />
             </div>
            </form>
            </div>
        </div>
    </div>
  )
}

export default AddProperty