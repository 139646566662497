import React, { useEffect, useState } from 'react'
import ProjectCom from '../Projects/ProjectCom'
import Section from './Sections'
import getServices from '../../Services/get-service'

function MainDetails() {
    const [pending, setPending] = useState('0')
    const [countUserInvestors, setCountUserInvestors] = useState('0')
    const [countUserFacilitator, setCountUserFacilitator] = useState('0')
    const [realtor, setRealtor] = useState('0')
    useEffect(() =>{
      getServices.getAllCount().then(
          (response) => {
             
            if(response.data.status === "2000"){
                setCountUserInvestors(response.data.data.countUserInvestors)
              setPending(response.data.data.countUserDeveloper)
              setCountUserFacilitator(response.data.data.countUserFacilitator)
              setRealtor(response.data.data.countUserRealtors)
            }else{
            //  swal(response.data.description)
            //  toast('Session Expired', {
            //   onClose: () => {
            //       window.location.href = '/'
            //   },
            //   autoClose: 5000
            // })
            }
            console.log(response.data.data)
          },
          (error) => {
             
          }
      )
  }, [])
  return (
    <div>
        <div className=' p-3 mb-4 border-b border-b-gray-200'>
            <p className=' text-[20px] font-[400]'>Overview</p>
        </div>
        <div className=' my-6 grid md:grid-cols-4 gap-4'>
            {/* <ProjectCom
                name='Total customers'
                count='39'
            /> */}
             <ProjectCom
                name='Total investors'
                count={countUserInvestors}
            />
             <ProjectCom
                name='Total Facilitator'
                count={countUserFacilitator}
            />
            <ProjectCom
                name='Total Developer'
                count={pending}
            />
            <ProjectCom
                name='Total Realtors'
                count={realtor}
            />
             {/* <ProjectCom
                name='New Signups'
                count='39'
            /> */}
        </div>
        <div>
            <Section/>
        </div>
    </div>
  )
}

export default MainDetails