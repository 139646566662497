import React from 'react'
import Invest from '../../Assets/images/in.png'
import Constant from '../../Constant'
function TopDCom({name, count, bool}) {

  return (

    <div className={bool?' mt-3 p-3 rounded-lg bg-white flex gap-4' : ' mt-3  rounded-lg p-3 bg-[#F8F8F8]'}>
        <div>
            <img src={Invest} alt='Invest'/>
        </div>
        <div>
            <p className='  font-[400] text-[14px] text-back-color2'>{name}</p>
            <p className=' font-[500] text-[20px] text-back-color2'>{ Constant.FormatNumber(count) }</p>
        </div>
    </div>
  )
}

export default TopDCom