import React from 'react'
import MainDetails from '../../Components/Customers/MainDetails'

function Customers() {
  return (
    <div>
    <MainDetails/>
    </div>
  )
}

export default Customers