import React, { useState } from 'react'
import Full from '../../Assets/images/fullp.png'
import Empty from '../../Assets/images/epro.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '../SelectValue/Button';
import { Link, useNavigate} from 'react-router-dom';
import postService from '../../Services/post-service';
import { ToastContainer, toast } from 'react-toastify';
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield';
import Inputtt from '../SelectValue/Inputtt';
import { Divider } from '@mui/material';
import ImageUploader from '../Dropdown';
import Pin from '../SelectValue/Pins';
import 'react-toastify/dist/ReactToastify.css';
function ProjectDetails() {
   
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const[name, setName] = useState('')
  const [pType, setPType] = useState('')
  const [contact, setContact] = useState('')
  const [earlyExit, setEarlyExit] = useState('')
  const [pricePerUnit, setPricePerUnit] = useState('')
  const [projectLocation, setProjectLocation] = useState('')
  const [des, setDes] = useState('')
  const [projectDuration, setProjectDuration] = useState('')
  const [projectLink, setProjectLink] = useState('')
  const [rate, setRate] = useState('');
  const [totalUnits, setTotalUnits] = useState('')
  const [transactionPin, setTransactionPin] = useState('')
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const navigate = useNavigate()
  const handleSubmit = (e) =>{
    e.preventDefault()
    setLoading(true)
    const formData = new FormData();
    images.forEach((image) => {
        formData.append('file', image);
      });
      formData.append('contact', contact);
      formData.append('earlyExit', earlyExit);
      formData.append('pricePerUnit',pricePerUnit);
      formData.append('projectDescription', des);
      formData.append('projectLocation', projectLocation);
      formData.append('projectDuration', projectDuration);
      formData.append('projectLink', projectLink);
      formData.append('projectName', name);
      formData.append('projectType', pType);
      formData.append('rate', rate);
      formData.append('totalUnits', totalUnits);
      formData.append('transactionPin', transactionPin);
      postService.uploadProjectt(formData).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            toast.success("Project added successfully!!");
            setTimeout(() => {
             navigate('/projects/active')
            }, 5000);
          
           console.log(response.data.description)
           }else{
            toast.error(response.data.description);
           
           }
           
          },
          (error) => {
            setLoading(false)
            toast.error(error.message);
            setTimeout(() => {
              window.location.reload()
            }, 5000);
            }
      )
}
const handleSubmitt = (e) =>{
  e.preventDefault()
  if(images.length > 0){
    setOpen(true)
  }else{
    toast.error("Select an image")
  }
}
  return (
    <div>
    <Pin
  open={open}
  handleClose={handleClose}
  loading={loading}
  onSubmit={handleSubmit}
  pin={transactionPin}
  setPin={setTransactionPin}
    />
    <ToastContainer/>
        <div className=' px-5 pt-3'>
            <p className=' pb-3'>Project details</p>
            {/* <div className=' flex justify-between gap-4'>
                <div>
                    <img src={Full} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Project details <CheckCircleIcon className=' text-primary-color'/></p>
                </div>
                <div>
                    <img src={Empty} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Pictures <CheckCircleOutlineIcon className=' text-[#44474E]'/></p>
                </div>
                <div>
                    <img src={Empty} alt='full'/>
                    <p className=' text-back-color2 text-[16px] pt-3 text-center'>Finish <CheckCircleOutlineIcon className=' text-[#44474E]'/></p>
                </div>
            </div> */}
            <div className='  mt-5'>
            <div className='p-3 px-10 mt-5'>
            <form onSubmit={handleSubmitt}>
                <div className='grid  my-4'>
                <Inputtt
                        label='Title'
                        value={name}
                        onChange={setName}
                    />
                </div>
                <p className=' font-[500] mb-4 text-[#44474E] text-[14px]'> <span className=' font-[800] text-back-color2 text-[14px]'>Note:</span>  Do NOT add location information to the listing title</p>
                <Divider/>
                <div className=' grid  my-4 '>
                     <div>
              <label className=' block mb-3'>Property type</label>
              <select className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg' required value={pType} onChange={(e) => setPType(e.target.value)}>
                <option value="">Select type</option>
                  <option key={1} value='land'>
                   Land
                  </option>
                  <option key={2} value='property'>
                   Property
                  </option>
              </select>
              </div>
                </div>
                <div className=' grid sm:grid-cols-1 gap-3  my-4 '>
                
                    <Inputtt
                        label='Contacts'
                        value={contact}
                        onChange={setContact}
                    />
                    <Inputtt
                        label='Location'
                        value={projectLocation}
                        onChange={setProjectLocation}
                    />
                    <div className=' w-full'>
                        <label className=' block mb-2'>Project Youtube Link</label> 
                            <input 
                                className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg'
                                value={projectLink}
                                onChange={(e) => setProjectLink(e.target.value)}
                            />
                        </div>
                </div>
                <div className=' grid  my-6 '>
                     <div>
              <label className=' block mb-3'>Project Duration</label>
              <select className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg' required value={projectDuration} onChange={(e) => setProjectDuration(e.target.value)}>
                <option value="">Select duration</option>
                <option key={0} value={730}>
                    2 year
                  </option>
                <option key={1} value={548}>
                    1 and 1/2 year
                  </option>
                  <option key={2} value={365}>
                    1 year
                  </option>
                  <option key={3} value={180}>
                    6 months
                  </option>
              </select>
              </div>
                </div>
                <div className=' grid  my-6 '>
                     <div>
              <label className=' block mb-3'>Select early Exist</label>
              <select className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg' required value={earlyExit} onChange={(e) => setEarlyExit(e.target.value)}>
                <option value="">Select a Minimum rent</option>
                <option key={1} value={548}>
                    1 and 1/2 year
                  </option>
                  <option key={2} value={365}>
                    1 year
                  </option>
                  <option key={3} value={180}>
                    6 months
                  </option>
              </select>
              </div>
                </div>
                <div className=' grid  my-4 '>
                        <NonnegativeInputField
                            label='Units'
                            valuee={totalUnits}
                            onChange={setTotalUnits}
                        />
                </div>
                <div className=' grid  my-4 '>
                        <NonnegativeInputField
                            label='Price per unit'
                            valuee={pricePerUnit}
                            onChange={setPricePerUnit}
                        />
                </div>
                <div className=' grid  my-4 '>
                        <NonnegativeInputField
                            label='Rate'
                            valuee={rate}
                            onChange={setRate}
                        />
                </div>
                <div className=' grid mb-16  my-4 '>
                    <label className=' mb-3 block'>Description</label>
                    <textarea
                    minLength={7}
                        className=' border p-4 outline-0 rounded-lg '
                        value={des}
                        required
                        onChange={(e) => setDes(e.target.value)}
                    />
                </div>
                <ImageUploader
                    images={images}
                    setImages={setImages}
                />
                 <div className=' flex mt-8 justify-end'>
                    <Button
                        name='Save continue'
                    />
             </div>
            </form>
        </div>
            </div>
        </div>
    </div>
  )
}

export default ProjectDetails