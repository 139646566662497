import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
function ProjectPriceForm() {
    const initialValues = { 
        email: '',
        password: '',

      }
      const validationSchema = Yup.object({
        
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
      //  password: Yup.string()
      //  .required('Please Enter your password')
      //  .matches(
      //    Constant.passwordValidation,
      //    Constant.passwordValidationMessage
      //  ),
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
       
       
       
      
      }
  return (
    <div>
        <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            <div>
            <FormikControl
                control='input'
                type='text'
                label='Project target(NGN)'
                name='target'
              />  
            
            </div>
            <div>
            <FormikControl
                control='input'
                type='text'
                label='Enter desired number of units'
                name='devname'
              />  
              
            </div>
            <div className=' pb-2 grid md:grid-cols-2 gap-4'>
            <FormikControl
                control='input'
                type='text'
                label='Generated price per unit(NGN)'
                name='generatep'
              />  
              <div className=' mt-8'>
              <Button
                name='save'
                bool={true}
              />
              </div>
            
            </div>
           
                <div className=' mt-10'>
                <Button
                name='save'
                bool={true}
              />
                </div>
        </Form>
        )}
            </Formik>
    </div>
  )
}

export default ProjectPriceForm