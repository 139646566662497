import React from 'react'
import Invest from '../../Assets/images/in.png'
import Constant from '../../Constant'
function TopDCom({name, count, bool}) {
  return (

    <div className=' mt-3 p-3 border border-gray-200 rounded-lg bg-white '>
    <p className='  font-[400] text-[14px] mb-2 text-back-color2'>{name}</p>
      <div className=' flex gap-4'>
      <div>
            <img src={Invest} alt='Invest'/>
        </div>
        <div>
            
            <p className=' font-[500] pt-3 text-[20px] text-back-color2'>{ Constant.FormatNumber(count) }</p>
        </div>
      </div>
        
    </div>
  )
}

export default TopDCom