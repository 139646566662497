import React from 'react'

function TexttArea({placeholder, value, label, type, onChange}) {
  return (
    <div className=' w-full'>
    <label className=' block mb-2'>{label}</label> 
    <textarea
     className=' w-full bg-[#F7F8FB] outline-0 p-3 rounded-lg'
     value={value}
     onChange={(e) => onChange(e.target.value)}
     minLength={4}
     />
    </div>
  )
}

export default TexttArea