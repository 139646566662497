import React from 'react'
import OtpForm from '../../Components/Otp/OtpForm'

function Otp() {
  return (
    <div className='bg-signin-bg'>
    <div className='  grid w-10/12 md:w-8/12   mx-auto h-[100vh] place-items-center'>
   
     <OtpForm/>
    </div>
    </div>
  )
}

export default Otp