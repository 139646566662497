import React, { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import ProjectCom from '../Projects/ProjectCom'
import ButtonPlus from '../SelectValue/ButtonPlus'
import PropertyTable from './PropertyTable'
import getServices from '../../Services/get-service'

function MainContent() {
   const [totalPropListed, setTotalPropListed] = useState("0")
   const [newListing, setNewListing] = useState("0")
   const [propSold, setPropSold] = useState("0")
   const [totalamountSold, setTotalAmountSold] = useState('0')

   useEffect(() =>{
    getServices.getAllCount().then(
        (response) => {
           
          if(response.data.status === "2000"){
            setPropSold(response.data.data.totalUnitsSold)
            setTotalAmountSold(response.data.data.totalSumpropertySold)
            setNewListing(response.data.data.totalSumListedProperty)
            setTotalPropListed(response.data.data.totalUnitsAvailable)
          }else{
          //  swal(response.data.description)
          //  toast('Session Expired', {
          //   onClose: () => {
          //       window.location.href = '/'
          //   },
          //   autoClose: 5000
          // })
          }
          console.log(response.data.data)
        },
        (error) => {
           
        }
    )
}, [])

  return (
    <div>
    <Outlet/>
    
        <div className='flex gap-6 mb-5'>
        <Link to='newproperty/property'>
        <ButtonPlus
                name='Property Listing'
            />
        </Link>
        <Link to='newproperty/land'>
        <ButtonPlus
                name='Land Listing'
            />
        </Link>
            
        </div>
        <div className=' my-5 grid md:grid-cols-4 gap-4'>
            <ProjectCom
                name='Total properties listed'
                count={totalPropListed === 'nu'? "0": totalPropListed}
            />
            <ProjectCom
                name='New listings'
                count={newListing === 'nu'? "0": newListing}
            />
            <ProjectCom
                name='Properties sold'
                count={propSold === 'nu'? "0": propSold}
            />
            <ProjectCom
                name='Total amount sold'
                count={totalamountSold === 'nu'? "0": totalamountSold}
            />
        </div>
        <div>
            <PropertyTable/>
        </div>
    </div>
  )
}

export default MainContent