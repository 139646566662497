import React from 'react'
import Button from '../../Components/SelectValue/Button'

function Profile() {
    const user = localStorage.getItem('userdetails')
    const userValue =  JSON.parse(user)
  return (
    <div>
        <div className=' grid grid-cols-2 md:grid-cols-2 gap-4 my-3'>
            <div className=' '>
            <p className=' mb-2 font-[400] text-[14px] text-[#44474E]'>Full name</p>
            <p className='mb-2 font-[500] text-[16px] text-[#1E222B]'>{userValue.firstName} {userValue.lastName}</p>
            </div>
           <div className=' flex flex-col  justify-center'>
                <p className=' text-primary-color'>Edit</p>
           </div>
        </div>
        <div className=' grid grid-cols-2 md:grid-cols-2 gap-4 my-3'>
            <div className=' '>
            <p className=' mb-2 font-[400] text-[14px] text-[#44474E]'>Email address</p>
            <p className='mb-2 font-[500] text-[16px] text-[#1E222B]'>{userValue.emailAddress}</p>
            </div>
           <div className=' flex flex-col  justify-center'>
                <p className=' text-primary-color'>Edit</p>
           </div>
        </div>
       
        <div className=' grid grid-cols-2 md:grid-cols-2 gap-4 my-3'>
            <div className=' '>
            <p className=' mb-2 font-[400] text-[14px] text-[#44474E]'>Role</p>
            <p className='mb-2 font-[500] text-[16px] text-[#1E222B]'>{userValue.userRole}</p>
            </div>
           <div className=' flex flex-col  justify-center'>
                <p className=' text-primary-color'>Edit</p>
           </div>
        </div>
        <div className=' grid grid-cols-2 md:grid-cols-2 gap-4 my-3'>
            <div className=' '>
            <p className=' mb-2 font-[400] text-[14px] text-[#44474E]'>Phone number</p>
            <p className='mb-2 font-[500] text-[16px] text-[#1E222B]'>{userValue.phoneNumber}</p>
            </div>
           <div className=' flex flex-col  justify-center'>
                <p className=' text-primary-color'>Edit</p>
           </div>
        </div>
        {/* <div className=' mt-5'>
            <Button
                name='Update'
            />
        </div> */}
    </div>
  )
}

export default Profile