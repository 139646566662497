import  React from 'react';
import Dashboardimg from '../../Assets/images/Category.svg'
import Buys from '../../Assets/images/Activity.png'
import Saves from '../../Assets/images/Graph.png'
import Listings from '../../Assets/images/b2.png'
import Logoutt from '../../Assets/images/logout.png'
import User from '../../Assets/images/user.png'
import People from '../../Assets/images/people.png'
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';


const superAdmin = [
    {
        name: 'Dashboard',
        icon: (
            <img src={Dashboardimg} className=' inline-block' alt='Dashboard'/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Investment',
        icon: (
          <img src={Buys} className=' inline-block'  alt='buy'/>
        ),
        link: '/investment',
        id: 'invest',
    },
    {
      name: 'Liquidate',
      icon: (
        <AssessmentIcon className=' text-gray-500'/>
      ),
      link: '/liquidate',
      id: 'liqui',
  },
  {
    name: 'Withdrawal',
    icon: (
      <AccountBalanceIcon className=' text-gray-500'/>
    ),
    link: '/withdrawal',
    id: 'withdrawal',
},
    {
      name: 'Projects',
      icon: (
        <img src={Listings} className=' inline-block' alt='Listings'/>
      ),
      link: '/projects',
      id: 'project',
  },
   
    {
        name: 'Savings',
        icon: (
          <img src={Saves} className=' inline-block' alt='Save'/>
        ),
        link: '/savings',
        id: 'save',
    },
   
    {
        name: 'Properties',
        icon: (
          <img src={Listings} className=' inline-block' alt='Listings'/>
        ),
        link: '/properties',
        id: 'properties',
    },
    {
      name: 'Customers',
      icon: (
        <img src={People} className=' inline-block' alt='People'/>
      ),
      link: '/customers',
      id: 'customers',
  },
    {
      name: 'User',
      icon: (
        <img src={User} className=' inline-block' alt='users'/>
      ),
      link: '/users',
      id: 'users',
  },
  {
    name: 'Notification',
    icon: (
      <NotificationsIcon className=' text-gray-500'/>
    ),
    link: '/notification',
    id: 'noti',
},
{
  name: 'Settings',
  icon: (
    <SettingsIcon/>
  ),
  link: '/settings',
  id: 'settings',
},
{
  name: 'Logout',
  icon: (
    <img src={Logoutt} className=' inline-block' alt='logut'/>
  ),
  link: '/logout',
  id: 'logout',
},
  ];

  const operationManager = [
    {
        name: 'Dashboard',
        icon: (
            <img src={Dashboardimg} className=' inline-block' alt='Dashboard'/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Investment',
        icon: (
          <img src={Buys} className=' inline-block'  alt='buy'/>
        ),
        link: '/investment',
        id: 'invest',
    },
   
    {
      name: 'Projects',
      icon: (
        <img src={Listings} className=' inline-block' alt='Listings'/>
      ),
      link: '/projects',
      id: 'project',
  },
   
    {
        name: 'Savings',
        icon: (
          <img src={Saves} className=' inline-block' alt='Save'/>
        ),
        link: '/savings',
        id: 'save',
    },
   
    {
        name: 'Properties',
        icon: (
          <img src={Listings} className=' inline-block' alt='Listings'/>
        ),
        link: '/properties',
        id: 'properties',
    },
    {
      name: 'Customers',
      icon: (
        <img src={People} className=' inline-block' alt='People'/>
      ),
      link: '/customers',
      id: 'customers',
  },
    {
      name: 'User',
      icon: (
        <img src={User} className=' inline-block' alt='users'/>
      ),
      link: '/users',
      id: 'users',
  },
  {
    name: 'Notification',
    icon: (
      <NotificationsIcon className=' text-gray-500'/>
    ),
    link: '/notification',
    id: 'noti',
},
{
  name: 'Settings',
  icon: (
    <SettingsIcon/>
  ),
  link: '/settings',
  id: 'settings',
},
{
  name: 'Logout',
  icon: (
    <img src={Logoutt} className=' inline-block' alt='logut'/>
  ),
  link: '/logout',
  id: 'logout',
},
  ];
  const projectManager =[
    {
        name: 'Dashboard',
        icon: (
            <img src={Dashboardimg} className=' inline-block' alt='Dashboard'/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Investment',
        icon: (
          <img src={Buys} className=' inline-block'  alt='buy'/>
        ),
        link: '/investment',
        id: 'invest',
    },
   
    {
      name: 'Projects',
      icon: (
        <img src={Listings} className=' inline-block' alt='Listings'/>
      ),
      link: '/projects',
      id: 'project',
  },
   
   
    {
        name: 'Properties',
        icon: (
          <img src={Listings} className=' inline-block' alt='Listings'/>
        ),
        link: '/properties',
        id: 'properties',
    },
    {
      name: 'Customers',
      icon: (
        <img src={People} className=' inline-block' alt='People'/>
      ),
      link: '/customers',
      id: 'customers',
  },
  
{
  name: 'Settings',
  icon: (
    <SettingsIcon/>
  ),
  link: '/settings',
  id: 'settings',
},
{
  name: 'Logout',
  icon: (
    <img src={Logoutt} className=' inline-block' alt='logut'/>
  ),
  link: '/logout',
  id: 'logout',
},
  ];
  const financeManager = [
    {
        name: 'Dashboard',
        icon: (
            <img src={Dashboardimg} className=' inline-block' alt='Dashboard'/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Investment',
        icon: (
          <img src={Buys} className=' inline-block'  alt='buy'/>
        ),
        link: '/investment',
        id: 'invest',
    },
    {
      name: 'Liquidate',
      icon: (
        <AssessmentIcon className=' text-gray-500'/>
      ),
      link: '/liquidate',
      id: 'liqui',
  },
  {
    name: 'Withdrawal',
    icon: (
      <AccountBalanceIcon className=' text-gray-500'/>
    ),
    link: '/withdrawal',
    id: 'withdrawal',
},
{
        name: 'Savings',
        icon: (
          <img src={Saves} className=' inline-block' alt='Save'/>
        ),
        link: '/savings',
        id: 'save',
    },
{
  name: 'Settings',
  icon: (
    <SettingsIcon/>
  ),
  link: '/settings',
  id: 'settings',
},
{
  name: 'Logout',
  icon: (
    <img src={Logoutt} className=' inline-block' alt='logut'/>
  ),
  link: '/logout',
  id: 'logout',
},
  ];

  const operator = [
    {
        name: 'Dashboard',
        icon: (
            <img src={Dashboardimg} className=' inline-block' alt='Dashboard'/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    
   
    {
      name: 'Projects',
      icon: (
        <img src={Listings} className=' inline-block' alt='Listings'/>
      ),
      link: '/projects',
      id: 'project',
  },
   
   
    {
        name: 'Properties',
        icon: (
          <img src={Listings} className=' inline-block' alt='Listings'/>
        ),
        link: '/properties',
        id: 'properties',
    },
  
{
  name: 'Settings',
  icon: (
    <SettingsIcon/>
  ),
  link: '/settings',
  id: 'settings',
},
{
  name: 'Logout',
  icon: (
    <img src={Logoutt} className=' inline-block' alt='logut'/>
  ),
  link: '/logout',
  id: 'logout',
},
  ];



export default localStorage.getItem("userRole") === 'SUPER_ADMIN' ? superAdmin : localStorage.getItem("userRole") === 'OPERATION_MANAGER' ?  operationManager : localStorage.getItem("userRole") === 'PROJECT_MANAGER' ?  projectManager : localStorage.getItem("userRole") === 'FINANCE_MANAGER' ?  financeManager : localStorage.getItem("userRole") === 'OPERATOR' ?  operator : operator