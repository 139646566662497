import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react'
import Listing from '../../Assets/images/pro1.png'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import Section from './Section';

const drawerWidth = 650;
function PendingProjectDrawer({open, handleClose, window}) {
    
  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
       <div>
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 text-[18px] pt-3'>Add new propject</p>
            <div className='pt-2'>
          <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/> 
            </div>  
        </div>
          <div className=' px-6 py-3'>
          <div className=' relative'>
                <img src={Listing} alt='List'/>
                <div className=' bg-white p-1 absolute top-2 left-2 rounded'>
                    <p className=' text-xs'> <PhotoCameraOutlinedIcon/> 10</p>
                </div>
            </div>
            <div className=' mt-4'>
                <p className=' font-[500] text-back-color2 text-[16px]'>Name of project goes here</p>
                <p className=' font-[500] text-back-color2 mb-2 text-[14px] '><PlaceOutlinedIcon className=' text-primary-color'/>Lagos, Ikeja</p>
            </div>
            <div className=' my-4 flex gap-5'>
                <div>
                    <p className=' bg-[#F5F7FA] text-[14px] font-[400] p-3 rounded-[32px] text-center'>Investment time period : 1 year</p>
                </div>
                <div>
                    <p className='bg-[#CCF2E1] text-[14px] p-3 rounded-[32px] text-center'>project target: NGN 500,000,000</p>
                </div>
            </div>
            <Section/>
          </div>
       </div>
         

        </Drawer>
    </div>
  )
}

export default PendingProjectDrawer