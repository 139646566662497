import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Bg from '../Assets/images/pimg.png'

function ImageSlider({imageList}) {
    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <div>
         <Slider {...settings}>
        {imageList.length > 0 ? imageList.map((image, index) => (
          <div key={index}>
            <img src={image}  alt={image.title} />
          </div>
        )) :
        <div >
            <img src={Bg} alt='Bg' />
          </div>
        }
      </Slider>
    </div>
  )
}

export default ImageSlider